import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SensorTagObserverService } from '../../service/sensor.tag.observer.service';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-voicing-st',
    templateUrl: './voicing-st.component.html',
    styleUrls: ['./voicing-st.component.css']
})
export class VoicingStComponent implements OnInit {
    @Output() sensorTagName: EventEmitter<string> = new EventEmitter<string>();

    lngVoicing: number = 4.561292;
    latVoicing: number = 51.880125;
    public visible: boolean;

    whoAmI(sensorTagName: any) {
        this.sensorTagName.emit(sensorTagName);
        this.sensorTagObserverService.getSubjectSTagTo().next({ name: sensorTagName });
        this.visible = !this.visible;
    }

    constructor(private sensorTagObserverService: SensorTagObserverService) {
        this.visible = false;
    }

    ngOnInit(): void {
        this.sensorTagObserverService.getSubjectTagTo()
            .subscribe((data: any) => {
                if (data.name === 'voicing') {
                    this.visible = data.visible;
                }
            });
            this.sensorTagObserverService.getSubjectModuleTo()
            .subscribe((data: any) => {
              if (data.name === 'voicing') {
                this.visible = !this.visible;
              }
            })
    }
}
