import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SensorTagObserverService } from '../../service/sensor.tag.observer.service';

@Component({
  selector: 'app-loitering-st',
  templateUrl: './loitering-st.component.html',
  styleUrls: ['./loitering-st.component.css']
})
export class LoiteringStComponent implements OnInit {
  @Output() sensorTagName: EventEmitter<string> = new EventEmitter<string>();

  lngLoitering: number = 4.552709;
  latLoitering: number = 51.879542;

  public visible: boolean;

  whoAmI(sensorTagName: any) {
    this.sensorTagName.emit(sensorTagName);
    this.sensorTagObserverService.getSubjectSTagTo().next({ name: sensorTagName });
    this.visible = !this.visible;
  }

  constructor(private sensorTagObserverService: SensorTagObserverService) {
    this.visible = false;
  }

  ngOnInit(): void {
    this.sensorTagObserverService.getSubjectTagTo()
      .subscribe((data: any) => {
        if (data.name === 'loitering') {
          this.visible = data.visible;
        }
      });
      this.sensorTagObserverService.getSubjectModuleTo()
      .subscribe((data: any) => {
        if (data.name === 'loitering') {
          this.visible = !this.visible;
        }
      })
  }

}
