
<div class="popup_container" [ngStyle]="styleFeedback" cdkDrag *ngIf="feedback">
    <svg class="infoPopup" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1301 781"><defs><style>.iipucls-1{fill:#e6e7e8;}.iipucls-2{fill:none;stroke:#bcbec0;}.iipucls-2,.iipucls-3{stroke-miterlimit:10;}.iipucls-3{fill:#fff;stroke:#dc4427;stroke-width:6px;}.iipucls-4{font-size:39.01px;fill:#6d6e71;font-family:Bolder_Bold, Bolder;font-weight:700;}</style></defs><rect class="iipucls-1" x="0.5" y="0.5" width="1300" height="780"/><rect class="iipucls-2" x="0.5" y="0.5" width="1300" height="780"/><g (click)="close()" style="cursor: pointer"><line class="iipucls-3" x1="46.24" y1="21.65" x2="30.59" y2="37.3"/><line class="iipucls-3" x1="46.24" y1="37.3" x2="30.59" y2="21.65"/></g>
        <foreignObject x="50" y="75" width="1200" height="1000">
            <div class="headerStyling">Feedback</div>
            <form class="contact" [formGroup]="form" method="post" (ngSubmit)="onSubmit()">
                <input [formControl]="name" placeholder="Name *" required>
                <input [formControl]="email" placeholder="Email">
                <textarea [formControl]="message" maxlength="512" placeholder="My thoughts … * &nbsp;&nbsp;&nbsp;**" required></textarea>
                <span>* Required &nbsp;&nbsp;&nbsp;** Max 512 characters</span>
                <button [disabled]="isLoading" class="submit" type="submit">Submit</button>
                <input [formControl]="honeypot" class="hidden" type="text" />
                <div [ngClass]="!submitted? 'hidden' : 'visible'" class="center-text">
                  <span id=feedbackThanks>{{responseMessage}}</span>
                </div>
            </form>
        </foreignObject>
    </svg>
</div>


<!-- <form class="contact" [formGroup]="form" method="post" (ngSubmit)="onSubmit()">
    <input [formControl]="name" placeholder="Name" required>
    <input [formControl]="email" placeholder="Mail: email@example.com">
    <textarea [formControl]="message" maxlength="256" placeholder="My feedback …" required></textarea>
    <button [disabled]="isLoading" class="submit" type="submit">Submit</button>
    <input [formControl]="honeypot" class="hidden" type="text" />
    <div [ngClass]="!submitted? 'hidden' : 'visible'" class="center-text">
      <span>{{responseMessage}}</span>
    </div>
</form> -->


