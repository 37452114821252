
<div class="container">
    <a href="#"  data-toggle="popover" data-img="../../assets/Info-01.svg" title="Popover Header" >Toggle popover</a>
    <a type="button" class="btn btn-outline-secondary mr-2" placement="bottom"
    ngbPopover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus." popoverTitle="Popover on bottom">
    Popover on bottom
</a>
    <h3 id="my-element">Popover Example</h3>
</div>

<button id="option2" autocomplete="off" data-toggle="popover"> Public</button>