import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../service/authentication.service';
import { Router } from '@angular/router';;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
    public isLoggedIn: boolean;

    constructor(private authenticationService: AuthenticationService,
                private router: Router) {
        this.isLoggedIn = this.authenticationService.isLoggedIn();
    }

    ngOnInit() {
        this.isLoggedIn = this.authenticationService.isLoggedIn();
    }

    login() {
        if(this.isLoggedIn) {
            this.authenticationService.logout();
            this.router.navigate(['login']);
        } else {
            this.authenticationService.login();
            this.router.navigate(['/rtdm/dashboard']);
        }

        this.isLoggedIn = this.authenticationService.isLoggedIn();
    }
}
