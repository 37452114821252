import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SensorTagObserverService } from '../../service/sensor.tag.observer.service';

@Component({
  selector: 'app-loitering',
  templateUrl: './loitering.component.html',
  styleUrls: ['./loitering.component.css']
})
export class LoiteringComponent implements OnInit {
  @Output() sensorTagName: EventEmitter<string> = new EventEmitter<string>();
  @Output() toggleDrag: EventEmitter<boolean> = new EventEmitter<boolean>();

  lngLoitering: number = 4.555463;
  latLoitering: number = 51.885498;

  default: boolean = true;
  public infoTagVisible: boolean;
  public infoHead!: string;
  public infoBody!: string;

  public style = {
    cursor: 'default',
    position: 'absolute',
    width: '250px',
    visibility: 'visible',
    transform: ""
  }

  public text = {
    def1: {
      header: 'Loitering',
      body: 'Sensor Display - A Sensor Display provides a represen- tation of the live sensor data from the neighbourhood. In this case a representation of recurring loitering activities.'
    },
    def2: {
      header: 'Loitering',
      body: 'Details - Use this to switch between the standard and detailed view of sensor data.'
    },
    def3: {
      header: 'Loitering',
      body: 'Sensor - Indication of the actual sensor and its status, active or inactive.'
    },
    def4: {
      header: 'Loitering',
      body: 'Gauge - Indication of the current deviation of recurring loitering activities. Including the most recent peak deviation.'
    },
    def5: {
      header: 'Loitering',
      body: 'Chart - Indicates the ratio of the most common, recurring loitering activities including \'standing\', \'sitting and \'talking\'.'
    },
    def6: {
      header: 'Loitering',
      body: 'Grapher - An overview of the deviation of recurring loitering activities for the chosen time frame.'
    },
    det1: {
      header: 'Loitering',
      body: 'Sensor Display - A Sensor Display provides a represen- tation of the live sensor data from the neighbourhood. In this case a representation of recurring loitering activities.'
    },
    det2: {
      header: 'Loitering',
      body: 'Details - Use this to switch between the standard and detailed view of sensor data.'
    },
    det3: {
      header: 'Loitering',
      body: 'Sensor - Indication of the actual sensor and its status, active or inactive.'
    },
    det4: {
      header: 'Loitering',
      body: 'Sound wave - Representation of the current sound sensing. Part of the Loitering Identifier.'
    },
    det5: {
      header: 'Loitering',
      body: 'Categorizer - Categorizes loitering behaviour on the basis of physical behaviour and sound sensing.'
    },
    det6: {
      header: 'Loitering',
      body: 'Loitering Identifier - Records physical loitering behaviour.'
    },
    det7: {
      header: 'Loitering',
      body: 'Identifier - Compares current loitering behaviour with loitering rituals from the past.'
    },
  }

  moduleClick(moduleName: string, locationName: string, event: any) {
    this.style.transform = 'translate3d(' + (String(event.layerX)) + 'px,' + (String(event.layerY)) + 'px, 0px)';
    this.infoHead = (this.text as any)[locationName].header;
    this.infoBody = (this.text as any)[locationName].body;
    this.infoTagVisible = !this.infoTagVisible;
  }

  moduleDrag() {
    this.toggleDrag.emit(false);
  }

  mapDrag() {
    this.toggleDrag.emit(true);
  }

  toggleDetails() {
    this.default = !this.default;
  }

  whoAmI(sensorTagName: any) {
    this.sensorTagName.emit(sensorTagName);
    this.sensorTagObserverService.getSubjectModuleTo().next({ name: sensorTagName });
    this.mapDrag();
    this.infoTagVisible = false;
  }

  closeInfoTag(value: boolean) {
    this.infoTagVisible = value;
    this.mapDrag();
  }

  constructor(private sensorTagObserverService: SensorTagObserverService) {
    this.infoTagVisible = false;
  }

  ngOnInit(): void {

  }

}
