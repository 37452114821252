<div class="sidebar" [ngClass]="{active : opened}">
    <div *ngIf="moduleviewPage" class="tag-viewport">
        <div (click)="animateLock()">
            <img class="not-allowed" src="../../assets/add-module-tag.svg" id="addModuleTag">
            <div *ngIf="viewedPage === 'rotterdam'">
                <div class="tag-item">
                    <tag-large class="tag-item" [active]="tags.moduleview.rotterdam.active" [headerText]="tags.moduleview.rotterdam.name" [bodyText]="tags.moduleview.rotterdam.type" [color]="tags.moduleview.rotterdam.color" [locked]="tags.moduleview.rotterdam.locked" [infoHead]="tags.moduleview.rotterdam.infoHead" [infoBody]="tags.moduleview.rotterdam.infoBody"></tag-large>
                    
                    <tag-large class="tag-item" [active]="tags.moduleview.reyeroord.active" [headerText]="tags.moduleview.reyeroord.name" [bodyText]="tags.moduleview.reyeroord.type" [color]="tags.moduleview.reyeroord.color" [locked]="tags.moduleview.reyeroord.locked" [infoHead]="tags.moduleview.reyeroord.infoHead" [infoBody]="tags.moduleview.reyeroord.infoBody"></tag-large>

                    <div *ngFor="let body of tags.moduleview.reyeroord.body">
                        <tag-medium [active]="body.active" [headerText]="body.name" [bodyText]="body.type" [color]="body.color" [locked]="body.locked" [infoHead]="body.infoHead" [infoBody]="body.infoBody"></tag-medium>
                        
                        <div *ngFor="let item of body.items">
                            <tag-small [active]="item.active" [headerText]="item.name" [bodyText]="item.type" [color]="item.color" [locked]="item.locked" [infoHead]="item.infoHead" [infoBody]="item.infoBody"></tag-small>
                        </div>
                    </div>

                    <tag-large class="tag-item" [active]="tags.moduleview.bospolder.active" [headerText]="tags.moduleview.bospolder.name" [bodyText]="tags.moduleview.bospolder.type" [color]="tags.moduleview.bospolder.color" [locked]="tags.moduleview.bospolder.locked" [infoHead]="tags.moduleview.bospolder.infoHead" [infoBody]="tags.moduleview.bospolder.infoBody"></tag-large>

                    <div *ngFor="let body of tags.moduleview.bospolder.body">
                        <tag-medium [active]="body.active" [headerText]="body.name" [bodyText]="body.type" [color]="body.color" [locked]="body.locked" [infoHead]="body.infoHead" [infoBody]="body.infoBody"></tag-medium>
                        
                        <div *ngFor="let item of body.items">
                            <tag-small [active]="item.active" [headerText]="item.name" [bodyText]="item.type" [color]="item.color" [locked]="item.locked" [infoHead]="item.infoHead" [infoBody]="item.infoBody"></tag-small>
                        </div>
                    </div>
                </div>
                <div class="marginCorrectionModRot"></div>
            </div>

            <div *ngIf="viewedPage === 'reyeroord'">
                <div class="tag-item">
                    <tag-large class="tag-item" [active]="tags.moduleview.reyeroord.active" [headerText]="tags.moduleview.reyeroord.name" [bodyText]="tags.moduleview.reyeroord.type" [color]="tags.moduleview.reyeroord.color" [locked]="tags.moduleview.reyeroord.locked" [infoHead]="tags.moduleview.reyeroord.infoHead" [infoBody]="tags.moduleview.reyeroord.infoBody"></tag-large>

                    <div *ngFor="let body of tags.moduleview.reyeroord.body">
                        <tag-medium [active]="body.active" [headerText]="body.name" [bodyText]="body.type" [color]="body.color" [locked]="body.locked" [infoHead]="body.infoHead" [infoBody]="body.infoBody"></tag-medium>
                        
                        <div *ngFor="let item of body.items">
                            <tag-small [active]="item.active" [headerText]="item.name" [bodyText]="item.type" [color]="item.color" [locked]="item.locked" [infoHead]="item.infoHead" [infoBody]="item.infoBody"></tag-small>
                        </div>
                    </div>
                </div>
                <div class="marginCorrectionModRey"></div>
            </div>

            <div *ngIf="viewedPage === 'bospolder'">
                <div class="tag-item">
                    <tag-large class="tag-item" [active]="tags.moduleview.bospolder.active" [headerText]="tags.moduleview.bospolder.name" [bodyText]="tags.moduleview.bospolder.type" [color]="tags.moduleview.bospolder.color" [locked]="tags.moduleview.bospolder.locked" [infoHead]="tags.moduleview.bospolder.infoHead" [infoBody]="tags.moduleview.bospolder.infoBody"></tag-large>

                    <div *ngFor="let body of tags.moduleview.bospolder.body">
                        <tag-medium [active]="body.active" [headerText]="body.name" [bodyText]="body.type" [color]="body.color" [locked]="body.locked" [infoHead]="body.infoHead" [infoBody]="body.infoBody"></tag-medium>
                        
                        <div *ngFor="let item of body.items">
                            <tag-small [active]="item.active" [headerText]="item.name" [bodyText]="item.type" [color]="item.color" [locked]="item.locked" [infoHead]="item.infoHead" [infoBody]="item.infoBody"></tag-small>
                        </div>
                    </div>
                </div>
                <div class="marginCorrectionModBos"></div>
            </div>
        </div>
    </div>

    <div *ngIf="!moduleviewPage" class="tag-viewport">
        <div *ngIf="viewedPage === 'rotterdam'">
            <div class="tag-item">
                <tag-large class="tag-item" [active]="tags.home.rotterdam.active" [headerText]="tags.home.rotterdam.name" [bodyText]="tags.home.rotterdam.type" [color]="tags.home.rotterdam.color" [locked]="tags.home.rotterdam.locked" [infoHead]="tags.home.rotterdam.infoHead" [infoBody]="tags.home.rotterdam.infoBody"></tag-large>
            </div>
            <div class="tag-item">
                <tag-large class="tag-item" [active]="tags.home.reyeroord.active" [headerText]="tags.home.reyeroord.name" [bodyText]="tags.home.reyeroord.type" [color]="tags.home.reyeroord.color" [locked]="tags.home.reyeroord.locked" [infoHead]="tags.home.reyeroord.infoHead" [infoBody]="tags.home.reyeroord.infoBody"></tag-large>
                <!-- <div *ngFor="let body of tags.home.reyeroord.body">
                    <tag-medium [active]="body.active" [headerText]="body.name" [bodyText]="body.type" [color]="body.color"></tag-medium>
                    
                    <div *ngFor="let item of body.items">
                        <tag-small [active]="item.active" [headerText]="item.name" [bodyText]="item.type" [color]="item.color"></tag-small>
                    </div>
                </div> -->
            </div>
            <div class="tag-item">
                <tag-large class="tag-item" [active]="tags.home.bospolder.active" [headerText]="tags.home.bospolder.name" [bodyText]="tags.home.bospolder.type" [color]="tags.home.bospolder.color" [locked]="tags.home.bospolder.locked" [infoHead]="tags.home.bospolder.infoHead" [infoBody]="tags.home.bospolder.infoBody"></tag-large>
                <!-- <div *ngFor="let body of tags.home.bospolder.body">
                    <tag-medium [active]="body.active" [headerText]="body.name" [bodyText]="body.type" [color]="body.color"></tag-medium>
                    
                    <div *ngFor="let item of body.items">
                        <tag-small [active]="item.active" [headerText]="item.name" [bodyText]="item.type" [color]="item.color"></tag-small>
                    </div>
                </div> -->
            </div>
        </div>

        <div *ngIf="viewedPage === 'reyeroord'">
            <div class="tag-item">
                <tag-large class="tag-item" [active]="tags.home.reyeroord.active" [headerText]="tags.home.reyeroord.name" [bodyText]="tags.home.reyeroord.type" [color]="tags.home.reyeroord.color" [locked]="tags.home.reyeroord.locked" [infoHead]="tags.home.reyeroord.infoHead" [infoBody]="tags.home.reyeroord.infoBody"></tag-large>

                <div *ngFor="let body of tags.home.reyeroord.body">
                    <tag-medium [active]="body.active" [headerText]="body.name" [bodyText]="body.type" [color]="body.color" [locked]="body.locked" [infoHead]="body.infoHead" [infoBody]="body.infoBody"></tag-medium>
                    
                    <div *ngFor="let item of body.items">
                        <tag-small [active]="item.active" [headerText]="item.name" [bodyText]="item.type" [color]="item.color" [locked]="item.locked" [infoHead]="item.infoHead" [infoBody]="item.infoBody"></tag-small>
                    </div>
                </div>
            </div>
            <div class="marginCorrectionRey"></div>
        </div>

        <div *ngIf="viewedPage === 'bospolder'">
            <div class="tag-item">
                <tag-large class="tag-item" [active]="tags.home.bospolder.active" [headerText]="tags.home.bospolder.name" [bodyText]="tags.home.bospolder.type" [color]="tags.home.bospolder.color" [locked]="tags.home.bospolder.locked" [infoHead]="tags.home.bospolder.infoHead" [infoBody]="tags.home.bospolder.infoBody"></tag-large>

                <div *ngFor="let body of tags.home.bospolder.body">
                    <tag-medium [active]="body.active" [headerText]="body.name" [bodyText]="body.type" [color]="body.color" [locked]="body.locked" [infoHead]="body.infoHead" [infoBody]="body.infoBody"></tag-medium>
                    
                    <div *ngFor="let item of body.items">
                        <tag-small [active]="item.active" [headerText]="item.name" [bodyText]="item.type" [color]="item.color" [locked]="item.locked" [infoHead]="item.infoHead" [infoBody]="item.infoBody"></tag-small>
                    </div>
                </div>
            </div>
            <div class="marginCorrectionBos"></div>
        </div>
    </div>
  
    <button class="toggle" (click)="toggleSidebar()">
        <svg id="menuArrow" [@rotatedState]='state' data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.5 86.6"><defs><style>.menuArrowFill{fill:#bbbdbf;}</style></defs><polygon class="menuArrowFill" [ngStyle]="arrowStyle" points="0 43.3 0 0 18.75 21.65 37.5 43.3 18.75 64.95 0 86.6 0 43.3"/></svg>
    </button>
</div>
<app-control-overlay></app-control-overlay>
<router-outlet class="midWrapper" [ngClass]="{marginCompensation : opened, marginCompensationNeg : marginCompensation}"></router-outlet>