import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SensorTagObserverService } from '../../service/sensor.tag.observer.service';

@Component({
  selector: 'app-broom',
  templateUrl: './broom.component.html',
  styleUrls: ['./broom.component.css']
})
export class BroomComponent implements OnInit {
  @Output() sensorTagName: EventEmitter<string> = new EventEmitter<string>();
  @Output() toggleDrag: EventEmitter<boolean> = new EventEmitter<boolean>();

  lngBroom: number = 4.434837;
  latBroom: number = 51.915396;

  default: boolean = true;
  public infoTagVisible: boolean;
  public infoHead!: string;
  public infoBody!: string;

  public style = {
    cursor: 'default',
    position: 'absolute',
    width: '250px',
    visibility: 'visible',
    transform: ""
  }

  public text = {
    def1: {
      header: 'Broom',
      body: 'Sensor Display - A Sensor Display provides a represen- tation of the live sensor data from the neighbourhood. In this case a representation of efforts to keep the neighbourhood clean.'
    },
    def2: {
      header: 'Broom',
      body: 'Details - Use this to switch between the standard and detailed view of sensor data.'
    },
    def3: {
      header: 'Broom',
      body: 'Sensor - Indication of the actual sensor and its status, active or inactive.'
    },
    def4: {
      header: 'Broom',
      body: 'Gauge - Indication of current efforts to clean the neighbour- hood. Including the most recent peak value.'
    },
    def5: {
      header: 'Broom',
      body: 'Chart - Indication of the ratio of the types of areas kept clean, including \'public\', \'commercial\' and \'private\'.'
    },
    def6: {
      header: 'Broom',
      body: 'Grapher - An overview of efforts to keep the neighbour- hood clean, for the chosen time frame.'
    },
    det1: {
      header: 'Broom',
      body: 'Sensor Display - A Sensor Display provides a represen- tation of the live sensor data from the neighbourhood. In this case a representation of efforts to keep the neighbourhood clean.'
    },
    det2: {
      header: 'Broom',
      body: 'Details - Use this to switch between the standard and detailed view of sensor data.'
    },
    det3: {
      header: 'Broom',
      body: 'Sensor - Indication of the actual sensor and its status, active or inactive.'
    },
    det4: {
      header: 'Broom',
      body: 'Chart - Indication of the cleanliness of the neighbour- hood, specified by location types including \'park\', \'street\' and \'square\'.'
    },
    det5: {
      header: 'Broom',
      body: 'Dynamic Map - An overview of the cleanliness of the neigh- bourhood, defined geograph- ically.'
    },
    det6: {
      header: 'Broom',
      body: 'Statistics - An overview of the daily average and the total area of the neighbourhood that is and has been kept clean, and the total time spent on neigh- bourhood cleaning with the Broom.'
    },
  }

  moduleClick(moduleName: string, locationName: string, event: any) {
    this.style.transform = 'translate3d(' + (String(event.layerX)) + 'px,' + (String(event.layerY)) + 'px, 0px)';
    this.infoHead = (this.text as any)[locationName].header;
    this.infoBody = (this.text as any)[locationName].body;
    this.infoTagVisible = !this.infoTagVisible;
  }

  moduleDrag() {
    this.toggleDrag.emit(false);
  }

  mapDrag() {
    this.toggleDrag.emit(true);
  }

  toggleDetails() {
    this.default = !this.default;
  }

  whoAmI(sensorTagName: any) {
    this.sensorTagName.emit(sensorTagName);
    this.sensorTagObserverService.getSubjectModuleTo().next({ name: sensorTagName });
    this.mapDrag();
    this.infoTagVisible = false;
  }

  closeInfoTag(value: boolean) {
    this.infoTagVisible = value;
    this.mapDrag();
  }

  constructor(private sensorTagObserverService: SensorTagObserverService) {
    this.infoTagVisible = false;
  }

  ngOnInit(): void {

  }

}
