<div class="container padding-top-40">
    <div class="row">
        <div class="col-sm">
            <img class="logo" src="../../assets/rotterdam-logo.svg">
        </div>
        <div class="col-sm">
        </div>
        <div class="col-sm">
            <a class="mijn-loket" (click)="login()">
                <span *ngIf="isLoggedIn === false">Log In</span>
                <span *ngIf="isLoggedIn === true">Log Out</span>
            </a>
        </div>
    </div>
</div>