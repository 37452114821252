import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SensorTagObserverService } from '../../service/sensor.tag.observer.service';

@Component({
  selector: 'app-purchases-st',
  templateUrl: './purchases-st.component.html',
  styleUrls: ['./purchases-st.component.css']
})
export class PurchasesStComponent implements OnInit {
  @Output() sensorTagName: EventEmitter<string> = new EventEmitter<string>();

  lngPurchases: number = 4.553567;
  latPurchases: number = 51.882920;
  public visible: boolean;

  whoAmI(sensorTagName: any) {
    this.sensorTagName.emit(sensorTagName);
    this.sensorTagObserverService.getSubjectSTagTo().next({ name: sensorTagName });
    this.visible = !this.visible;
  }

  constructor(private sensorTagObserverService: SensorTagObserverService) {
    this.visible = false;
  }

  ngOnInit(): void {
    this.sensorTagObserverService.getSubjectTagTo()
      .subscribe((data: any) => {
        if (data.name === 'purchases') {
          this.visible = data.visible;
        }
      });
      this.sensorTagObserverService.getSubjectModuleTo()
      .subscribe((data: any) => {
        if (data.name === 'purchases') {
          this.visible = !this.visible;
        }
      })
  }

}
