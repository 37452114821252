<div class="mapControl not-allowed" id="line">
    <img src="../../assets/line-icon-blocked.svg">
</div>
<svg class="pointer" id="lock" (click)="lockedClick=!lockedClick" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.37 49.72"><defs><style>.lockFill{fill:#bcbec0;}.lockStroke{fill:none;stroke:#bcbec0;stroke-miterlimit:10;stroke-width:6px;}</style></defs><rect class="lockFill" [ngClass]="{'lockedFill' : lockedElementsClick}" x="14.82" y="25.17" width="24.54" height="24.54" rx="3.99" ry="3.99" transform="translate(64.54 10.35) rotate(90)"/><rect class="lockFill" [ngClass]="{'lockedFill' : lockedElementsClick}" y="25.17" width="24.54" height="24.54" rx="3.99" ry="3.99" transform="translate(49.72 25.17) rotate(90)"/><path class="lockStroke" [ngClass]="{'lockedStroke' : lockedElementsClick}" d="M19.58,3A13.57,13.57,0,0,1,33.16,16.58V29.63"/><path class="lockStroke" [ngClass]="{'lockedStroke' : lockedElementsClick}" d="M19.79,3A13.58,13.58,0,0,0,6.2,16.58V29.63"/></svg>
<div class="mapControl blur pointer" *ngIf="lockedClick" (click)="lockedClick=!lockedClick">
    <div (click)="$event.stopPropagation()">
    <label for="password" ></label>
    <input id="password" type="password" placeholder="">
</div>
</div>

