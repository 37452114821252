import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SensorTagObserverService } from '../../service/sensor.tag.observer.service';

@Component({
  selector: 'app-gates',
  templateUrl: './gates.component.html',
  styleUrls: ['./gates.component.css']
})
export class GatesComponent implements OnInit {
  @Output() sensorTagName: EventEmitter<string> = new EventEmitter<string>();
  @Output() toggleDrag: EventEmitter<boolean> = new EventEmitter<boolean>();

  lngGates: number = 4.438837;
  latGates: number = 51.915396;

  default: boolean = true;
  public infoTagVisible: boolean;
  public infoHead!: string;
  public infoBody!: string;

  public style = {
    cursor: 'default',
    position: 'absolute',
    width: '250px',
    visibility: 'visible',
    transform: ""
  }

  public text = {
    def1: {
      header: 'Gates',
      body: 'Sensor Display - A Sensor Display provides a represen- tation of the live sensor data from the neighbourhood. In this case a representation of the movement of people through gates from one side to the other.'
    },
    def2: {
      header: 'Gates',
      body: 'Sensor - Indication of the actual sensor and its status, active or inactive.'
    },
    def3: {
      header: 'Gates',
      body: 'Gauge - Indication of the current movement flow through gates'
    },
    def4: {
      header: 'Gates',
      body: 'Chart - Indication of the ratio of inflow, outflow, and closed gates.'
    },
    def5: {
      header: 'Gates',
      body: 'Grapher - An overview of the relationship between \'inflow\' and \'outflow\', for the chosen time frame.'
    },
    def6: {
      header: 'Gates',
      body: 'Identifier - Registers and categorizes the use, direction- ality and status of a gate.'
    },
  }

  moduleClick(moduleName: string, locationName: string, event: any) {
    this.style.transform = 'translate3d(' + (String(event.layerX)) + 'px,' + (String(event.layerY)) + 'px, 0px)';
    this.infoHead = (this.text as any)[locationName].header;
    this.infoBody = (this.text as any)[locationName].body;
    this.infoTagVisible = !this.infoTagVisible;
  }

  moduleDrag() {
    this.toggleDrag.emit(false);
  }

  mapDrag() {
    this.toggleDrag.emit(true);
  }

  toggleDetails() {
    this.default = !this.default;
  }

  whoAmI(sensorTagName: any) {
    this.sensorTagName.emit(sensorTagName);
    this.sensorTagObserverService.getSubjectModuleTo().next({ name: sensorTagName });
    this.mapDrag();
    this.infoTagVisible = false;
  }

  closeInfoTag(value: boolean) {
    this.infoTagVisible = value;
    this.mapDrag();
  }

  constructor(private sensorTagObserverService: SensorTagObserverService) {
    this.infoTagVisible = false;
  }

  ngOnInit(): void {

  }

}
