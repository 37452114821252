import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ruler',
  templateUrl: './ruler.component.html',
  styleUrls: ['./ruler.component.css']
})
export class RulerComponent implements OnInit {
  public visible!: boolean;

  public style = {
      cursor: 'default',
      position: 'fixed',
      width: '445px',
      top: '',
      left: '',
  }

  specialClick(event: any){
    this.style.top = ("calc(" + String(event.pageY) + "px - 192px)") ;
    this.style.left = ("calc(" + String(event.pageX) + "px - 445px)");
    this.visible = !this.visible;
  }
  constructor() { }

  ngOnInit(): void {
  }

}
