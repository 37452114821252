import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SensorTagObserverService } from '../../service/sensor.tag.observer.service';

@Component({
  selector: 'app-voicing',
  templateUrl: './voicing.component.html',
  styleUrls: ['./voicing.component.css']
})
export class VoicingComponent implements OnInit {
  @Output() sensorTagName: EventEmitter<string> = new EventEmitter<string>();
  @Output() toggleDrag: EventEmitter<boolean> = new EventEmitter<boolean>();

  lngVoicing: number = 4.545463;
  latVoicing: number = 51.885498;

  default: boolean = true;
  public infoTagVisible: boolean;
  public infoHead!: string;
  public infoBody!: string;

  public style = {
    cursor: 'default',
    position: 'absolute',
    width: '250px',
    visibility: 'visible',
    transform: ""
  }

  public text = {
    def1: {
      header: 'Voicing',
      body: 'Sensor Display - A Sensor Display provides a represen- tation of the live sensor data from the neighbourhood. In the case of Voicing, vocal utter- ances, shouting and mumbling.'
    },
    def2: {
      header: 'Voicing',
      body: 'Sound wave - Representation of the current sound sensing.'
    },
    def3: {
      header: 'Voicing',
      body: 'Sensor - Indication of the actual sensor and its status, active or inactive.'
    },
    def4: {
      header: 'Voicing',
      body: 'Gauge - Indication of the ratio between \'shouting\' and \'mumbling\'.'
    },
    def5: {
      header: 'Voicing',
      body: 'Chart - Indication of the rela- tionship between shouting, mumbling and \'normal\' voice.'
    },
    def6: {
      header: 'Voicing',
      body: 'Grapher - An overview of the relationship between \'shouting\' and \'mumbling\' for the chosen time frame.'
    },
  }

  moduleClick(moduleName: string, locationName: string, event: any) {
    this.style.transform = 'translate3d(' + (String(event.layerX)) + 'px,' + (String(event.layerY)) + 'px, 0px)';
    this.infoHead = (this.text as any)[locationName].header;
    this.infoBody = (this.text as any)[locationName].body;
    this.infoTagVisible = !this.infoTagVisible;
  }

  moduleDrag() {
    this.toggleDrag.emit(false);
  }

  mapDrag() {
    this.toggleDrag.emit(true);
  }

  toggleDetails() {
    this.default = !this.default;
  }

  whoAmI(sensorTagName: any) {
    this.sensorTagName.emit(sensorTagName);
    this.sensorTagObserverService.getSubjectModuleTo().next({ name: sensorTagName });
    this.mapDrag();
    this.infoTagVisible = false;
  }

  closeInfoTag(value: boolean) {
    this.infoTagVisible = value;
    this.mapDrag();
  }

  constructor(private sensorTagObserverService: SensorTagObserverService) {
    this.infoTagVisible = false;
  }

  ngOnInit(): void {

  }

}
