import { Injectable } from "@angular/core";
import { LocalStorageService } from './local.storage.service';


@Injectable()
export class AuthenticationService {
    constructor(private localStorageService: LocalStorageService) {
    }

    login() {
        this.localStorageService.set('user', true);
    }

    logout(): void {
        this.localStorageService.remove('user');
    }

    isLoggedIn(): boolean {
        if (this.localStorageService.get('user') === null) {
            return false;
        }

        return true;
    }
}
