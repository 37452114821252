import { Component, OnInit, OnDestroy } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { StatesService } from '../states.service'
import { RouterService } from '../service/router.service';
import { SensorTagObserverService } from '../service/sensor.tag.observer.service';

@Component({
  selector: 'app-mid',
  templateUrl: './mid.component.html',
  styleUrls: ['./mid.component.css'],
  animations: [
    // Each unique animation requires its own trigger. The first argument of the trigger function is the name
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(180deg)' })),
      transition('rotated => default', animate('300ms ease-out')),
      transition('default => rotated', animate('300ms ease-in'))
  ])
]
})
export class MidComponent implements OnInit, OnDestroy {
 
    public opened: boolean = false;
    marginCompensation: boolean = true;
    _dock: boolean = true;
    state: string = 'default';

    toggleSidebar() {
        this.opened = !this.opened;
        this.marginCompensation = !this.marginCompensation;
        this.state = (this.state === 'default' ? 'rotated' : 'default');

        let parameters = this.router.url.substring(this.router.url.indexOf("?") + 1);
        let obj = JSON.parse('{"' + decodeURI(parameters.replace(/&/g, "\",\"").replace(/=/g,"\":\"")) + '"}');
        obj.barOpen = this.opened;

        let baseUrl = this.router.url.split('?')[0];
        this.router.navigate([baseUrl], { relativeTo: this.route, queryParams: obj});
    };
   
    private subscription: any;
    public viewedPage!: string;
    public moduleviewPage!: boolean;
    
    // red #dc4427
    // gray #a7a9ac
    // rotterdam gray #bcbec0
    // yellow #f8bb49
    // green #75a842
    // #278a45
    // botu #238944
    // blue #509cb7
    // #4c9bb8
    // pink #d1b9b8
    // brown #b78070
    public tags = {
        moduleview: {
            rotterdam: {
                        name: 'Rotterdam',
                        type: 'Display',
                        color: '#bcbec0',
                        locked: true,
                        active: 'inactive',
                        infoHead: '',
                        infoBody: '',  
                    },
            reyeroord: {
                    name: 'Reyeroord',
                    type: 'Display',
                    color: '#dc4427',
                    locked: true,
                    active: 'inactive',
                    infoHead: '',
                    infoBody: '',  
                    body: [
                        {
                            name: 'Outspokenness',
                            type: 'Display',
                            color: '#b78070',
                            locked: true,
                            active: 'inactive',
                            infoHead: '',
                            infoBody: '',  
                            items: [
                                {
                                    name: 'Voicing',
                                    type: 'Sensor',
                                    color: '#b78070',
                                    locked: true,
                                    active: 'active',
                                    infoHead: 'Sensor',
                                    infoBody: 'To enable the Sensor - and render it visible as a Sensor Tag in the map view - you have to place it on the board. A Sensor can be connected - and thereby serves as input - to a Display.<br>Placing a Sensor on the board is done by clicking its (this) Menu Tag.',  
                                },
                                {
                                    name: 'Reviews',
                                    type: 'Sensor',
                                    color: '#b78070',
                                    locked: true,
                                    active: 'inactive',
                                    infoHead: '',
                                    infoBody: '',  
                                },
                                {
                                    name: 'Behaviour ball',
                                    type: 'Sensor',
                                    color: '#b78070',
                                    locked: true,
                                    active: 'inactive',
                                    infoHead: '',
                                    infoBody: '',   
                                }
                            ]
                        },
                        {
                            name: 'Trust / Mistrust',
                            type: 'Display',
                            color: '#a7a9ac',
                            locked: true,
                            active: 'inactive',
                            infoHead: '',
                            infoBody: '',  
                            items: [
                                {
                                    name: 'Dog leash',
                                    type: 'Sensor',
                                    color: '#a7a9ac',
                                    locked: true,
                                    active: 'inactive',
                                    infoHead: '',
                                    infoBody: '',  
                                },
                                {
                                    name: 'Purchases',
                                    type: 'Sensor',
                                    color: '#a7a9ac',
                                    locked: true,
                                    active: 'inactive',
                                    infoHead: '',
                                    infoBody: '',
                                },
                                {
                                    name: 'City Manager',
                                    type: 'Sensor',
                                    color: '#a7a9ac',
                                    locked: true,
                                    active: 'active',
                                    infoHead: 'Sensor',
                                    infoBody: 'To enable the Sensor - and render it visible as a Sensor Tag in the map view - you have to place it on the board. A Sensor can be connected - and thereby serves as input - to a Display.<br>Placing a Sensor on the board is done by clicking its (this) Menu Tag.',
                                }
                            ]
                        },
                        {
                            name: 'Boredom',
                            type: 'Display',
                            color: '#75a842',
                            locked: true,
                            active: 'inactive',
                            infoHead: '',
                            infoBody: '',  
                            items: [
                                {
                                    name: 'Loitering',
                                    type: 'Sensor',
                                    color: '#75a842',
                                    locked: true,
                                    active: 'inactive',
                                    infoHead: '',
                                    infoBody: '',  
                                },
                                {
                                    name: 'Geraniums',
                                    type: 'Sensor',
                                    color: '#75a842',
                                    locked: true,
                                    active: 'active',
                                    infoHead: 'Sensor',
                                    infoBody: 'To enable the Sensor - and render it visible as a Sensor Tag in the map view - you have to place it on the board. A Sensor can be connected - and thereby serves as input - to a Display.<br>Placing a Sensor on the board is done by clicking its (this) Menu Tag.',
                                },
                                {
                                    name: 'Bodily Express-',
                                    type: 'Sensor',
                                    color: '#75a842',
                                    locked: true,
                                    active: 'active',
                                    infoHead: 'Sensor',
                                    infoBody: 'To enable the Sensor - and render it visible as a Sensor Tag in the map view - you have to place it on the board. A Sensor can be connected - and thereby serves as input - to a Display.<br>Placing a Sensor on the board is done by clicking its (this) Menu Tag.',
                                }
                            ]
                        }
                    ]
            },
            bospolder: {
                name: 'Bospolder/Tussendi-',
                type: 'Display',
                color: '#278a45',
                locked: true,
                active: 'inactive',
                infoHead: '',
                infoBody: '',
                body: [
                    {
                        name: 'Care',
                        type: 'Display',
                        color: '#f8bb49',
                        locked: true,
                        active: 'inactive',
                        infoHead: '',
                        infoBody: '',
                        items: [
                            {
                                name: 'Gift wrap',
                                type: 'Sensor',
                                color: '#f8bb49',
                                locked: true,
                                active: 'inactive',
                                infoHead: '',
                                infoBody: '',
                            },
                            {
                                name: 'Crosswalk',
                                type: 'Sensor',
                                color: '#f8bb49',
                                locked: true,
                                active: 'inactive',
                                infoHead: '',
                                infoBody: '',
                            },
                            {
                                name: 'Broom',
                                type: 'Sensor',
                                color: '#f8bb49',
                                locked: true,
                                active: 'inactive',
                                infoHead: '',
                                infoBody: '',
                            }
                        ]
                    },
                    {
                        name: 'Ingenuity / Initia-',
                        type: 'Display',
                        color: '#4c9bb8',
                        locked: true,
                        active: 'inactive',
                        infoHead: '',
                        infoBody: '',
                        items: [
                            {
                                name: 'Shopping cart',
                                type: 'Sensor',
                                color: '#4c9bb8',
                                locked: true,
                                active: 'inactive',
                                infoHead: '',
                                infoBody: '',
                            },
                            {
                                name: 'Jerry rigging',
                                type: 'Sensor',
                                color: '#4c9bb8',
                                locked: true,
                                active: 'active',
                                infoHead: 'Sensor',
                                infoBody: 'To enable the Sensor - and render it visible as a Sensor Tag in the map view - you have to place it on the board. A Sensor can be connected - and thereby serves as input - to a Display.<br>Placing a Sensor on the board is done by clicking its (this) Menu Tag.',
                            },
                            {
                                name: 'Sensors Kit',
                                type: 'Sensor',
                                color: '#4c9bb8',
                                locked: true,
                                active: 'active',
                                infoHead: 'Sensor',
                                infoBody: 'To enable the Sensor - and render it visible as a Sensor Tag in the map view - you have to place it on the board. A Sensor can be connected - and thereby serves as input - to a Display.<br>Placing a Sensor on the board is done by clicking its (this) Menu Tag.',
                            },
                        ]
                    },
                    {
                        name: 'Inclusion / Exclu-',
                        type: 'Display',
                        color: '#d1b9b8',
                        locked: true,
                        active: 'inactive',
                        infoHead: '',
                        infoBody: '',
                        items: [
                            {
                                name: 'Gates',
                                type: 'Sensor',
                                color: '#d1b9b8',
                                locked: true,
                                active: 'inactive',
                                infoHead: '',
                                infoBody: '',
                            },
                            {
                                name: 'Boys & Girls',
                                type: 'Sensor',
                                color: '#d1b9b8',
                                locked: true,
                                active: 'active',
                                infoHead: 'Sensor',
                                infoBody: 'To enable the Sensor - and render it visible as a Sensor Tag in the map view - you have to place it on the board. A Sensor can be connected - and thereby serves as input - to a Display.<br>Placing a Sensor on the board is done by clicking its (this) Menu Tag.',
                            },
                            {
                                name: 'Wealth',
                                type: 'Sensor',
                                color: '#d1b9b8',
                                locked: true,
                                active: 'active',
                                infoHead: 'Sensor',
                                infoBody: 'To enable the Sensor - and render it visible as a Sensor Tag in the map view - you have to place it on the board. A Sensor can be connected - and thereby serves as input - to a Display.<br>Placing a Sensor on the board is done by clicking its (this) Menu Tag.',
                            },
                        ]
                    }
                ]
            }
        },
        home: {
            rotterdam: {
                name: 'Rotterdam',
                type: 'Display',
                color: '#bcbec0',
                locked: true,
                active: 'construction', 
                infoHead: 'Display',
                infoBody: 'A Display that presents a combination of Neighbourhood Display data. In this case Reyeroord and Bospolder Tussendijken.<br>This Display is currently under construction and unavailable. ', 
            },
            reyeroord: {
                    name: 'Reyeroord',
                    type: 'Display',
                    color: '#dc4427',
                    locked: true,
                    active: 'construction',
                    infoHead: 'Display',
                    infoBody: 'A Display that presents A combination of Category Display data, to say something about neighbourhood specific doings.<br>This Display is currently under construction and unavailable. ',
                    body: [
                        {
                            name: 'Outspokenness',
                            type: 'Display',
                            color: '#b78070',
                            locked: false,
                            active: 'active',
                            infoHead: 'Display',
                            infoBody: 'A Display presents combined - and further interpreted - sensor data, belonging to the same category. In this case Sensors which say something about Outspokenness.<br>A Display is enabled by clicking its (this) Menu Tag.',
                            items: [
                                {
                                    name: 'Voicing',
                                    type: 'Sensor (tag)',
                                    color: '#b78070',
                                    locked: false,
                                    active: 'active',
                                    infoHead: 'Sensor Tag',
                                    infoBody: 'When placed on the map the Sensor Tag indicates the position of the Sensor and, when clicked, opens the corresponding Sensor Display, including live sensor data.<br>Placing the Sensor Tag on the map is done by clicking its (this) Menu Tag.',
                                },
                                {
                                    name: 'Reviews',
                                    type: 'Sensor (tag)',
                                    color: '#b78070',
                                    locked: false,
                                    active: 'active',
                                    infoHead: 'Sensor Tag',
                                    infoBody: 'When placed on the map the Sensor Tag indicates the position of the Sensor and, when clicked, opens the corresponding Sensor Display, including live sensor data.<br>Placing the Sensor Tag on the map is done by clicking its (this) Menu Tag.',
                                },
                                {
                                    name: 'Behaviour ball',
                                    type: 'Sensor (tag)',
                                    color: '#b78070',
                                    locked: false,
                                    active: 'active',
                                    infoHead: 'Sensor Tag',
                                    infoBody: 'When placed on the map the Sensor Tag indicates the position of the Sensor and, when clicked, opens the corresponding Sensor Display, including live sensor data.<br>Placing the Sensor Tag on the map is done by clicking its (this) Menu Tag.',
                                }
                            ]
                        },
                        {
                            name: 'Trust / Mistrust',
                            type: 'Display',
                            color: '#a7a9ac',
                            locked: true,
                            active: 'construction',
                            infoHead: 'Display',
                            infoBody: 'A Display presents combined - and further interpreted - sensor data, belonging to the same category. In this case Sensors which say something about Trust / Mistrust.<br>This Display is currently under construction and unavailable. ',
                            items: [
                                {
                                    name: 'Dog leash',
                                    type: 'Sensor (tag)',
                                    color: '#a7a9ac',
                                    locked: false,
                                    active: 'active',
                                    infoHead: 'Sensor Tag',
                                    infoBody: 'When placed on the map the Sensor Tag indicates the position of the Sensor and, when clicked, opens the corresponding Sensor Display, including live sensor data.<br>Placing the Sensor Tag on the map is done by clicking its (this) Menu Tag.',
                                },
                                {
                                    name: 'Purchases',
                                    type: 'Sensor (tag)',
                                    color: '#a7a9ac',
                                    locked: false,
                                    active: 'active',
                                    infoHead: 'Sensor Tag',
                                    infoBody: 'When placed on the map the Sensor Tag indicates the position of the Sensor and, when clicked, opens the corresponding Sensor Display, including live sensor data.<br>Placing the Sensor Tag on the map is done by clicking its (this) Menu Tag.',
                                },
                            ]
                        },
                        {
                            name: 'Boredom',
                            type: 'Display',
                            color: '#75a842',
                            locked: true,
                            active: 'construction',
                            infoHead: 'Display',
                            infoBody: 'A Display presents combined - and further interpreted - sensor data, belonging to the same category. In this case Sensors which say something about Boredom.<br>This Display is currently under construction and unavailable. ',
                            items: [
                                {
                                    name: 'Loitering',
                                    type: 'Sensor (tag)',
                                    color: '#75a842',
                                    locked: false,
                                    active: 'active',
                                    infoHead: 'Sensor Tag',
                                    infoBody: 'When placed on the map the Sensor Tag indicates the position of the Sensor and, when clicked, opens the corresponding Sensor Display, including live sensor data.<br>Placing the Sensor Tag on the map is done by clicking its (this) Menu Tag.',
                                },
                            ]
                        }
                    ]
            },
            bospolder: {
                name: 'Bospolder Tussendi-',
                type: 'Display',
                color: '#278a45',
                locked: true,
                active: 'construction',
                infoHead: 'Display',
                infoBody: 'A Display that presents A combination of Category Display data, to say something about neighbourhood specific doings.<br>This Display is currently under construction and unavailable. ',
                body: [
                    {
                        name: 'Care',
                        type: 'Display',
                        color: '#f8bb49',
                        locked: false,
                        active: 'active',
                        infoHead: 'Display',
                        infoBody: 'A Display presents combined - and further interpreted - sensor data, belonging to the same category. In this case Sensors which something about Care.<br>A Display is enabled by clicking its (this) Menu Tag.',
                        items: [
                            {
                                name: 'Gift wrap',
                                type: 'Sensor (tag)',
                                color: '#f8bb49',
                                locked: false,
                                active: 'active',
                                infoHead: 'Sensor Tag',
                                infoBody: 'When placed on the map the Sensor Tag indicates the position of the Sensor and, when clicked, opens the corresponding Sensor Display, including live sensor data.<br>Placing the Sensor Tag on the map is done by clicking its (this) Menu Tag.',
                            },
                            {
                                name: 'Crosswalk',
                                type: 'Sensor (tag)',
                                color: '#f8bb49',
                                locked: false,
                                active: 'active',
                                infoHead: 'Sensor Tag',
                                infoBody: 'When placed on the map the Sensor Tag indicates the position of the Sensor and, when clicked, opens the corresponding Sensor Display, including live sensor data.<br>Placing the Sensor Tag on the map is done by clicking its (this) Menu Tag.',
                            },
                            {
                                name: 'Broom',
                                type: 'Sensor (tag)',
                                color: '#f8bb49',
                                locked: false,
                                active: 'active',
                                infoHead: 'Sensor Tag',
                                infoBody: 'When placed on the map the Sensor Tag indicates the position of the Sensor and, when clicked, opens the corresponding Sensor Display, including live sensor data.<br>Placing the Sensor Tag on the map is done by clicking its (this) Menu Tag.',
                            }
                        ]
                    },
                    {
                        name: 'Ingenuity / Initia-',
                        type: 'Display',
                        color: '#4c9bb8',
                        locked: true,
                        active: 'construction',
                        infoHead: 'Display',
                        infoBody: 'A Display presents combined - and further interpreted - sensor data, belonging to the same category. In this case Sensors which say something about Ingenuity / Initiative.<br>This Display is currently under construction and unavailable. ',
                        items: [
                            {
                                name: 'Shopping cart',
                                type: 'Sensor (tag)',
                                color: '#4c9bb8',
                                locked: false,
                                active: 'active',
                                infoHead: 'Sensor Tag',
                                infoBody: 'When placed on the map the Sensor Tag indicates the position of the Sensor and, when clicked, opens the corresponding Sensor Display, including live sensor data.<br>Placing the Sensor Tag on the map is done by clicking its (this) Menu Tag.',
                            },
                        ]
                    },
                    {
                        name: 'Inclusion / Exclu-',
                        type: 'Display',
                        color: '#d1b9b8',
                        locked: true,
                        active: 'construction',
                        infoHead: 'Display',
                        infoBody: 'A Display presents combined - and further interpreted - sensor data, belonging to the same category. In this case Sensors which say something about Inclusion / Exlusion.<br>This Display is currently under construction and unavailable. ',
                        items: [
                            {
                                name: 'Gates',
                                type: 'Sensor (tag)',
                                color: '#d1b9b8',
                                locked: false,
                                active: 'active',
                                infoHead: 'Sensor Tag',
                                infoBody: 'When placed on the map the Sensor Tag indicates the position of the Sensor and, when clicked, opens the corresponding Sensor Display, including live sensor data.<br>Placing the Sensor Tag on the map is done by clicking its (this) Menu Tag.',
                            },
                        ]
                    }
                ]
            }
        }
    };

    public arrowStyle: object = {fill: '#bcbec0'}

    locationArrowStyling(location: string) {
        switch (location) {
            case 'rotterdam':
                this.arrowStyle = {fill: '#bcbec0'};
                break;
            case 'reyeroord':
                this.arrowStyle = {fill: '#dc4427'};
                break;
            case 'bospolder':
                this.arrowStyle = {fill: '#278a45'};
                break;
        }
    }
    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                public statesservice: StatesService,
                private route: ActivatedRoute,
                private sensorTagObserverService: SensorTagObserverService,
                private routerService: RouterService) { }

    ngOnInit(): void {
        this.subscription = this.router.events.subscribe((val) => {
            if(val instanceof NavigationEnd) {
                this.moduleviewPage = this.router.url.includes('boardview');
                let parameters = window.location.search.substring(window.location.search.indexOf("?") + 1);

                if(parameters === '') {
                } else {
                    let obj = JSON.parse('{"' + decodeURI(parameters.replace(/&/g, "\",\"").replace(/=/g,"\":\"")) + '"}');
                    this.viewedPage = obj.location;
                    this.locationArrowStyling(obj.location);
                    
                    if(obj.barOpen === 'true') {
                        this.opened = true;
                        this.marginCompensation = false;
                        this.state = 'rotated';
                    } else if(obj.barOpen === 'false') {
                        this.opened = false;
                        this.marginCompensation = true;
                        this.state = 'default';
                    }
                }
            }
        });
    }

    animateLock() {
        if(this.routerService.getBaseUrl().includes('moduleview')) {
            let value = true;
            let params = this.routerService.getQueryParams();
            
            if(params.tagClicked === 'true') {
                value = false;
            }

            this.routerService.addParamToUrl({
                name: 'tagClicked',
                value: value
            })
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}
