import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
  constructor() { }

    get(name: string) {
        return window.localStorage.getItem(name);
    }

    getSession(name: string) {
        return window.sessionStorage.getItem(name);
    }

    set(name: string, data: any) {
        window.localStorage.setItem(name, data);
    }

    setSession(name: string, data: any) {
        window.sessionStorage.setItem(name, data);
    }

    exist(name: string): boolean {
        return this.get(name) ? true : false;
    }

    existSession(name: string): boolean {
        return this.getSession(name) ? true : false;
    }

    remove(name: string) {
        window.localStorage.removeItem(name);
    }
}
