import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { RouterService } from '../service/router.service';

@Component({
  selector: 'app-control-overlay-extended',
  templateUrl: './control-overlay-extended.component.html',
  styleUrls: ['./control-overlay-extended.component.css']
})
export class ControlOverlayExtendedComponent implements OnInit, OnDestroy {

    public lockedClick = false;
    public lockedElementsClick = true;
    public subscription!: any;

    constructor(private router: Router,
                private routerService: RouterService) { }

    ngOnInit(): void {
        this.subscription = this.router.events.subscribe((val) => {
            if(val instanceof NavigationEnd) {
                var a = this;
                setTimeout(function() {
                    a.lockedElementsClick = false;
                    setTimeout(function() {
                        a.lockedElementsClick = true;
                    }, 10);
                }, 10);
            }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
