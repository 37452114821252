import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class InfoTagObserverService {
    
    private subjectCloseTag = new Subject();

    constructor() {}

    getSubjectCloseTag() {
        return this.subjectCloseTag;
    }

}
