<div class="small-ruler ruler" id="start"></div>
<div class="small-ruler ruler"></div>
<span class="text-ruler ruler">A</span>
<div class="small-ruler ruler"></div>
<div class="small-ruler ruler"></div>
<div class="big-ruler ruler"></div>
<div class="small-ruler ruler"></div>
<div class="small-ruler ruler"></div>
<span class="text-ruler ruler">B</span>
<div class="small-ruler ruler"></div>
<div class="small-ruler ruler"></div>
<div class="big-ruler ruler"></div>
<div class="small-ruler ruler"></div>
<div class="small-ruler ruler"></div>
<span class="text-ruler ruler">C</span>
<div class="small-ruler ruler"></div>
<div class="small-ruler ruler"></div>
<div class="big-ruler ruler"></div>
<div class="small-ruler ruler"></div>
<div class="small-ruler ruler"></div>
<span class="text-ruler ruler">D</span>
<div class="small-ruler ruler"></div>
<div class="small-ruler ruler"></div>
<div class="big-ruler ruler"></div>
<div class="small-ruler ruler"></div>
<div class="small-ruler ruler"></div>
<span class="text-ruler ruler">E</span>
<div class="small-ruler ruler"></div>
<div class="small-ruler ruler"></div>
<div class="big-ruler ruler"></div>
<div class="small-ruler ruler"></div>
<div class="small-ruler ruler"></div>
<span class="text-ruler ruler">F</span>
<div class="small-ruler ruler"></div>
<div class="small-ruler ruler"></div>
<div class="big-ruler ruler"></div>
<div class="small-ruler ruler"></div>
<div class="small-ruler ruler"></div>
<span class="text-ruler ruler">G</span>
<div class="small-ruler ruler"></div>
<div class="small-ruler ruler"></div>
<div class="big-ruler ruler"></div>
<div class="small-ruler ruler"></div>
<div class="small-ruler ruler"></div>
<span class="text-ruler ruler">H</span>
<div class="small-ruler ruler"></div>
<div class="small-ruler ruler"></div>
<div class="big-ruler ruler"></div>
<div class="small-ruler ruler"></div>
<div class="small-ruler ruler"></div>
<span class="text-ruler ruler">I</span>
<div class="small-ruler ruler"></div>
<div class="small-ruler ruler"></div>
<div class="big-ruler ruler"></div>
<div class="small-ruler ruler"></div>
<div class="small-ruler ruler"></div>
<span class="text-ruler ruler">J</span>
<div class="small-ruler ruler"></div>
<div class="small-ruler ruler"></div>
<div class="big-ruler ruler"></div>
<div class="small-ruler ruler"></div>
<div class="small-ruler ruler"></div>
<span class="text-ruler ruler">K</span>
<div class="small-ruler ruler"></div>
<div class="small-ruler ruler"></div>
<div class="big-ruler ruler"></div>
<div class="small-ruler ruler"></div>
<div class="small-ruler ruler"></div>
<span class="text-ruler ruler">L</span>
<div class="small-ruler ruler"></div>
<div class="small-ruler ruler"></div>
<div class="big-ruler ruler"></div>
<div class="small-ruler ruler"></div>
<div class="small-ruler ruler"></div>
<span class="text-ruler ruler">M</span>
<div class="small-ruler ruler"></div>
<div class="small-ruler ruler"></div>
<div class="big-ruler ruler"></div>
<div id="icon_container">
    <svg id="copyright_img" (click)="specialClick($event)" style="cursor: pointer;" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63.62 63.62"><defs><style>.cricls-1{fill:#e7e8e9;}.cricls-2{font-size:70px;fill:#6d6f71;font-family:Bolder_Regular, Bolder;}</style></defs><circle class="cricls-1" cx="31.81" cy="31.81" r="31.81"/><text class="cricls-2" transform="translate(12.2 51)">c</text></svg>
    <!-- <app-info-svg #copyTag *ngIf="visible" cdkDrag (closedSpecial)="closeSpecialTag($event)" [headerText]="infoHead" [bodyText]="infoBody" [ngStyle]="style"></app-info-svg> -->
</div>
<div id="copyright_container" [ngStyle]="style" cdkDrag *ngIf="visible">
    <svg id="copyrightInfo" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 916 391"><defs><style>.cripucls-1{fill:#e6e7e8;}.cripucls-2{fill:none;stroke:#bcbec0;}.cripucls-2,.cripucls-3{stroke-miterlimit:10;}.cripucls-3{fill:#fff;stroke:#dc4427;stroke-width:6px;}</style></defs><rect class="cripucls-1" x="0.5" y="0.5" width="915" height="390"/><rect class="cripucls-2" x="0.5" y="0.5" width="915" height="390"/><g style="cursor: pointer" (click)="specialClick($event)" id="close"><line class="cripucls-3" x1="48.24" y1="21.65" x2="32.59" y2="37.3"/><line class="cripucls-3" x1="48.24" y1="37.3" x2="32.59" y2="21.65"/></g>
        <foreignObject x="50" y="75" width="900" height="550">
            <div class="headerStyling">Copyright</div>
            <div class="bodyStyling">Both the concept and the unstable media production are the intellectual property of V2_Lab, TU Delft and Peter Kalkman. <br><br>Materialisation and (speculative) design by <a href="https://kalkman.love" target="_blank" style="color: purple; text-decoration: none"> kalkman.love<img width="50" height="50" src="../../assets/kalkman.png"></a></div>
            <!-- <div class="bodyStyling2">Materialisation and (speculative) design by <a href="https://kalkman.love" target="_blank" style="color: purple; text-decoration: none"> kalkman.love<img width="50" height="50" src="../../assets/kalkman.png"></a></div> -->
        </foreignObject>
    </svg>
</div>
<!-- (click)="specialClick($event)" -->
<!-- [ngStyle]="style" -->