import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from "./service/authentication.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    public isLoggedIn: boolean;

    constructor(private authenticationService: AuthenticationService) {
        this.isLoggedIn = this.authenticationService.isLoggedIn();
    }

    ngOnInit(): void {
        this.isLoggedIn = this.authenticationService.isLoggedIn();
    }
}
