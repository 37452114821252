import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { InfoTagObserverService } from '../service/info.tag.observer.service';

@Component({
    selector: 'app-info-svg',
    templateUrl: './info-svg.component.html',
    styleUrls: ['./info-svg.component.css']
})
export class InfoSvgComponent implements OnInit {
    @Input() headerText!: string;
    @Input() bodyText!: string;
    @Output() closedInfo: EventEmitter<boolean> = new EventEmitter<boolean>();

    public infoSubject: any;
    public bodyText1!: string;
    public bodyText2!: string;

    splitBody() {
        const splitText = this.bodyText.split('<br>');
        this.bodyText1 = splitText[0];
        this.bodyText2 = splitText ? splitText[1] : '';
    }

    close() {
        this.closedInfo.emit(false);
        $(".rotterdamSvg").css({ visibility: 'hidden' });
    }

    constructor(public infoTagObserverService: InfoTagObserverService) { }

    ngOnInit(): void {
        this.infoTagObserverService.getSubjectCloseTag()
            .subscribe((data: any) => {
                this.closedInfo.emit(false);
            });
        this.splitBody()
    }

}
