import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SensorTagObserverService } from '../../service/sensor.tag.observer.service';

@Component({
  selector: 'app-outspokenness',
  templateUrl: './outspokenness.component.html',
  styleUrls: ['./outspokenness.component.css']
})
export class OutspokennessComponent implements OnInit {
  @Output() toggleDrag: EventEmitter<boolean> = new EventEmitter<boolean>();

  lngOutspokenness: number = 4.543463;
  latOutspokenness: number = 51.885498;
  //51.885508448888686, 4.543517168180819
  //51.885498515569836, 4.543463524002854

  default: boolean = true;
  public visible: boolean;
  public infoTagVisible: boolean;
  public infoHead!: string;
  public infoBody!: string;

  public style = {
    cursor: 'default',
    position: 'absolute',
    width: '250px',
    visibility: 'visible',
    transform: ""

  }
  public text = {
    def1: {
      header: 'Outspokenness',
      body: 'Display - presents combined and analyzed Sensor data. In this case representing Outspokenness Sensors, including \'Voicing\' (vocal utterances), \'Reviews\' (sentiment) and \'Behaviour ball\' (physical behaviour).'
    },
    def2: {
      header: 'Outspokenness',
      body: 'Sensor - Indication of the actual Sensor (Reviews) and its status, active or inactive.'
    },
    def3: {
      header: 'Outspokenness',
      body: 'Sensor - Indication of the actual Sensor (Behaviour ball) and its status, active or inactive.'
    },
    def4: {
      header: 'Outspokenness',
      body: 'Sensor - Indication of the actual Sensor (Voicing) and its status, active or inactive.'
    },
    def5: {
      header: 'Outspokenness',
      body: 'Gauge - Indication of current Outspokenness in the neigh- bourhood based on relevant, combined Sensor data'
    },
    def6: {
      header: 'Outspokenness',
      body: 'Chart - Indication of the rela- tionship between Sensor data relevant to Outspokenness in the neighbourhood.'
    },
    def7: {
      header: 'Outspokenness',
      body: 'Grapher - An overview of Outspokenness in the neigh- bourhood, for the chosen time frame.'
    },
  }

  whoAmI(name: string) {
    this.visible = false;
    this.infoTagVisible = false;
    this.mapDrag();
    this.sensorTagObserverService.getSubjectModuleTo().next({ name: name });
  }

  moduleClick(moduleName: string, locationName: string, event: any) {
    this.style.transform = 'translate3d(' + (String(event.layerX)) + 'px,' + (String(event.layerY)) + 'px, 0px)';
    this.infoHead = (this.text as any)[locationName].header;
    this.infoBody = (this.text as any)[locationName].body;
    this.infoTagVisible = !this.infoTagVisible;
  }

  toggleDetails() {
    this.default = !this.default;
  }

  closeInfoTag(value: boolean) {
    this.infoTagVisible = value;
    this.mapDrag();
  }

  moduleDrag() {
    this.toggleDrag.emit(false);
  }

  mapDrag() {
    this.toggleDrag.emit(true);
  }

  constructor(private sensorTagObserverService: SensorTagObserverService) {
    this.visible = false;
    this.infoTagVisible = false;
  }

  ngOnInit(): void {
    this.sensorTagObserverService.getSubjectTagTo()
      .subscribe((data: any) => {
        if (data.name === 'outspokenness') {
          this.visible = data.visible;
        }
      })
  }

}

