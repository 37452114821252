import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { MapComponent } from './map/map.component';
import { MidComponent } from './mid/mid.component';
import { LoginComponent } from './login/login.component';
import { LeegComponent } from './leeg/leeg.component';
import { ModuleViewComponent } from './module-view/module-view.component';

const routes: Routes = [
    {
        path: 'rtdm/dashboard',
        component: MapComponent
    },
    {
        path: 'rtdm/dashboard/boardview',
        component: ModuleViewComponent
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'leeg',
        component: LeegComponent
    },
    {
        path: '**',
        redirectTo: 'rtdm/dashboard'
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
