import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SensorTagObserverService } from '../../service/sensor.tag.observer.service';

@Component({
  selector: 'app-care',
  templateUrl: './care.component.html',
  styleUrls: ['./care.component.css']
})
export class CareComponent implements OnInit {
  @Output() toggleDrag: EventEmitter<boolean> = new EventEmitter<boolean>();

  lngCare: number = 4.428837;
  latCare: number = 51.915396;

  default: boolean = true;
  public visible: boolean;
  public infoTagVisible: boolean;
  public infoHead!: string;
  public infoBody!: string;

  public style = {
    cursor: 'default',
    position: 'absolute',
    width: '250px',
    visibility: 'visible',
    transform: ""

  }
  public text = {
    def1: {
      header: 'Care',
      body: 'Display - presents combined and analyzed Sensor data. In this case representing Care Sensors, including \'Gift wrap\' (gifting), \'Crosswalk\' (assis- tance) and \'Broom\' (neighbour- hood cleanliness).'
    },
    def2: {
      header: 'Care',
      body: 'Sensor - Indication of the actual Sensor (Crosswalk) and its status, active or inactive.'
    },
    def3: {
      header: 'Care',
      body: 'Sensor - Indication of the actual Sensor (Broom) and its status, active or inactive.'
    },
    def4: {
      header: 'Care',
      body: 'Sensor - Indication of the actual Sensor (Gift wrap) and its status, active or inactive.'
    },
    def5: {
      header: 'Care',
      body: 'Gauge - Indication of current Care in the neighbourhood based on relevant, combined Sensor data.'
    },
    def6: {
      header: 'Care',
      body: 'Chart - Indication of the relationship between Sensor data relevant to Care in the neighbourhood.'
    },
    def7: {
      header: 'Care',
      body: 'Grapher - An overview of Care in the neighbourhood, for the chosen time frame.'
    },
  }

  whoAmI(name: string) {
    this.visible = false;
    this.infoTagVisible = false;
    this.mapDrag();
    this.sensorTagObserverService.getSubjectModuleTo().next({ name: name });
  }

  moduleClick(moduleName: string, locationName: string, event: any) {
    this.style.transform = 'translate3d(' + (String(event.layerX)) + 'px,' + (String(event.layerY)) + 'px, 0px)';
    this.infoHead = (this.text as any)[locationName].header;
    this.infoBody = (this.text as any)[locationName].body;
    this.infoTagVisible = !this.infoTagVisible;
  }

  toggleDetails() {
    this.default = !this.default;
  }

  closeInfoTag(value: boolean) {
    this.infoTagVisible = value;
    this.mapDrag();
  }

  moduleDrag() {
    this.toggleDrag.emit(false);
  }

  mapDrag() {
    this.toggleDrag.emit(true);
  }

  constructor(private sensorTagObserverService: SensorTagObserverService) {
    this.visible = false;
    this.infoTagVisible = false;
  }

  ngOnInit(): void {
    this.sensorTagObserverService.getSubjectTagTo()
      .subscribe((data: any) => {
        if (data.name === 'care') {
          this.visible = data.visible;
        }
      })
  }

}
