import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-tag',
  templateUrl: './info-tag.component.html',
  styleUrls: ['./info-tag.component.css']
})
export class InfoTagComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }

}
