import { Injectable } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

@Injectable()
export class RouterService {
    constructor(private router: Router, private route: ActivatedRoute) {
    }

    addToUrl(location: string, barOpen: any = null) {
        if(this.urlIsEmpty()) {
            let newParams = {
                location: location
            };

            if(barOpen !== null) {
                (newParams as any).barOpen = barOpen;
            }

            this.router.navigate([this.router.url], { relativeTo: this.route, queryParams: newParams});
        } else {
            let parameters = this.router.url.substring(this.router.url.indexOf("?") + 1);

            let obj = JSON.parse('{"' + decodeURI(parameters.replace(/&/g, "\",\"").replace(/=/g,"\":\"")) + '"}');
            if(obj.location) {
                obj.location = location;
            }
            if(barOpen !== null) {
                obj.barOpen = barOpen;
            }
    
            this.goToUrl(this.getBaseUrl(), obj);
        };

    }

    addParamToUrl(param: {name: string, value: any}) {
        if(this.urlIsEmpty()) {
            this.router.navigate([this.router.url], { relativeTo: this.route, queryParams: param});
        }

        let parameters = this.router.url.substring(this.router.url.indexOf("?") + 1);

        let obj = JSON.parse('{"' + decodeURI(parameters.replace(/&/g, "\",\"").replace(/=/g,"\":\"")) + '"}');
        obj[param.name] = param.value;

        this.goToUrl(this.getBaseUrl(), obj);

    }

    urlIsEmpty(): boolean {
        let parameters = this.router.url.substring(this.router.url.indexOf("?") + 1);
        return parameters === this.router.url;
    }

    goToUrl(url: string, paramaters: Object) {
        this.router.navigate([url], { relativeTo: this.route, queryParams: paramaters});
    }

    getBaseUrl() {
        return this.router.url.split('?')[0];
    }

    getQueryParams() {
        let parameters = this.router.url.substring(this.router.url.indexOf("?") + 1);

        try {
            return JSON.parse('{"' + decodeURI(parameters.replace(/&/g, "\",\"").replace(/=/g,"\":\"")) + '"}');
        } catch(e) {
            return false;
        }
    }
}
