import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from "../service/authentication.service";
import { Router } from '@angular/router';;

@Component({
  selector: 'app-mid-header',
  templateUrl: './mid-header.component.html',
  styleUrls: ['./mid-header.component.css']
})
export class MidHeaderComponent implements OnInit {
    public isLoggedIn: boolean;
    public info!: boolean;
    public language!: boolean;
    public feedback!: boolean;
    public legend!: boolean;
    
    public styleLanguage = {
        cursor: 'default',
        position: 'fixed',
        width: '445px',
        top: '',
        left: '',
    }
    public styleInfo = {
        cursor: 'default',
        position: 'fixed',
        width: '625px',
        top: 'calc(50vh - 188px',
        left: 'calc(50vw - 312px)',
    }
    public styleFeedback = {
        cursor: 'default',
        position: 'fixed',
        width: '445px',
        top: '',
        left: '',
    }
    public styleLegend = {
        cursor: 'default',
        position: 'fixed',
        width: '625px',
        top: 'calc(50vh - 188px',
        left: 'calc(50vw - 312px)',
    }
    specialClick(button: string, event: any){
        switch (button) {
            case'info': {
                this.info = !this.info;
                // this.styleInfo.top = (String(event.layerY) + "px") ;
                // this.styleInfo.left = (String(event.layerX) + "px)");
                console.log(event);
                break;
            }
            case'language': {
                this.language = !this.language;
                this.styleLanguage.top = (String(event.pageY) + "px") ;
                this.styleLanguage.left = (String(event.pageX) + "px)");
                break;
            }
            case'feedback': {
                this.feedback = !this.feedback;
                // this.styleFeedback.top = (String(event.pageY) + "px") ;
                // this.styleFeedback.left = (String(event.pageX) + "px)");
                break;
            }
            case'legend': {
                this.legend = !this.legend;
                // this.styleLegend.top = (String(event.pageY) + "px") ;
                // this.styleLegend.left = (String(event.pageX) + "px)");
                break;
            }       
        }
    }
    constructor(private authenticationService: AuthenticationService,
                private router: Router) {
        this.isLoggedIn = this.authenticationService.isLoggedIn();
    }

    ngOnInit(): void {
        this.isLoggedIn = this.authenticationService.isLoggedIn();
    }

    login() {
        if(this.isLoggedIn) {
            this.authenticationService.logout();
            this.router.navigate(['login']);
            location.reload();
        } else {
            this.authenticationService.login();
            this.router.navigate(['/rtdm/dashboard']);
            location.reload();
        }

        this.isLoggedIn = this.authenticationService.isLoggedIn();
    }
}
