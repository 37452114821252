import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';

import { SidebarModule } from 'ng-sidebar';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgmCoreModule } from '@agm/core';
import { AgmOverlays } from "agm-overlays"
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DragScrollModule } from 'ngx-drag-scroll';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from "@angular/common/http";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { LeegComponent } from './leeg/leeg.component';
import { MapComponent } from './map/map.component'

import { TagLargeComponent } from './tag/large/tag.large.component';
import { TagMediumComponent } from './tag/medium/tag.medium.component';
import { TagSmallComponent } from './tag/small/tag.small.component';

import { LogoComponent } from './logo/logo.component';
import { MidHeaderComponent } from './mid-header/mid-header.component';
import { SettingsComponent } from './settings/settings.component';
import { StaticMenuComponent } from './static-menu/static-menu.component';
import { MidComponent } from './mid/mid.component';
import { RulerComponent } from './ruler/ruler.component';

import { LocalStorageService } from './service/local.storage.service';
import { AuthenticationService } from './service/authentication.service';
import { RouterService } from './service/router.service';
import { SensorTagObserverService } from './service/sensor.tag.observer.service';
import { InfoTagObserverService } from './service/info.tag.observer.service';
import { ModuleViewComponent } from './module-view/module-view.component';
import { InfoTagComponent } from './info-tag/info-tag.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InfoSvgComponent } from './info-svg/info-svg.component';
import { ControlOverlayComponent } from './control-overlay/control-overlay.component';
import { ControlOverlayExtendedComponent } from './control-overlay-extended/control-overlay-extended.component';
import { OutspokennessComponent } from './modules/outspokenness/outspokenness.component';
import { VoicingComponent } from './modules/voicing/voicing.component';
import { ReviewsComponent } from './modules/reviews/reviews.component';
import { SensorObjectComponent } from './modules/sensor-object/sensor-object.component';
import { DogLeashComponent } from './modules/dog-leash/dog-leash.component';
import { PurchasesComponent } from './modules/purchases/purchases.component';
import { LoiteringComponent } from './modules/loitering/loitering.component';
import { VoicingStComponent } from './sensor-tags/voicing-st/voicing-st.component';
import { ReviewsStComponent } from './sensor-tags/reviews-st/reviews-st.component';
import { SensorObjectStComponent } from './sensor-tags/sensor-object-st/sensor-object-st.component';
import { DogLeashStComponent } from './sensor-tags/dog-leash-st/dog-leash-st.component';
import { PurchasesStComponent } from './sensor-tags/purchases-st/purchases-st.component';
import { LoiteringStComponent } from './sensor-tags/loitering-st/loitering-st.component';
import { CareComponent } from './modules/care/care.component';
import { GiftwrapComponent } from './modules/giftwrap/giftwrap.component';
import { CrosswalkComponent } from './modules/crosswalk/crosswalk.component';
import { BroomComponent } from './modules/broom/broom.component';
import { ShoppingcartComponent } from './modules/shoppingcart/shoppingcart.component';
import { GatesComponent } from './modules/gates/gates.component';
import { GiftwrapStComponent } from './sensor-tags/giftwrap-st/giftwrap-st.component';
import { CrosswalkStComponent } from './sensor-tags/crosswalk-st/crosswalk-st.component';
import { BroomStComponent } from './sensor-tags/broom-st/broom-st.component';
import { ShoppingcartStComponent } from './sensor-tags/shoppingcart-st/shoppingcart-st.component';
import { GatesStComponent } from './sensor-tags/gates-st/gates-st.component';
import { FormComponent } from './form/form.component';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        HeaderComponent,
        LoginComponent,
        LeegComponent,
        MapComponent,
        LogoComponent,
        MidHeaderComponent,
        SettingsComponent,
        StaticMenuComponent,
        MidComponent,
        RulerComponent,
        ModuleViewComponent,
        InfoTagComponent,
        InfoSvgComponent,
        TagLargeComponent,
        TagMediumComponent,
        TagSmallComponent,
        ControlOverlayComponent,
        ControlOverlayExtendedComponent,
        OutspokennessComponent,
        VoicingComponent,
        ReviewsComponent,
        SensorObjectComponent,
        DogLeashComponent,
        PurchasesComponent,
        LoiteringComponent,
        VoicingStComponent,
        ReviewsStComponent,
        SensorObjectStComponent,
        DogLeashStComponent,
        PurchasesStComponent,
        LoiteringStComponent,
        CareComponent,
        GiftwrapComponent,
        CrosswalkComponent,
        BroomComponent,
        ShoppingcartComponent,
        GatesComponent,
        GiftwrapStComponent,
        CrosswalkStComponent,
        BroomStComponent,
        ShoppingcartStComponent,
        GatesStComponent,
        FormComponent,
    ],
    imports: [
        BrowserModule,
        CommonModule,
        AppRoutingModule,
        SidebarModule,
        ScrollingModule,
        BrowserAnimationsModule,
        NgbModule,
        AgmOverlays,
        DragDropModule,
        DragScrollModule,
        ReactiveFormsModule,
        HttpClientModule,
        AgmCoreModule.forRoot({
            apiKey: '*'
        })
    ],
    providers: [
        LocalStorageService,
        AuthenticationService,
        RouterService,
        SensorTagObserverService,
        InfoTagObserverService
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule { }
