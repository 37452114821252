import { style } from '@angular/animations';
import { Component, OnInit, Input } from '@angular/core';
import { SensorTagObserverService } from '../../service/sensor.tag.observer.service';
import { InfoTagObserverService } from '../../service/info.tag.observer.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'tag-large',
  templateUrl: './tag.large.component.html',
  styleUrls: ['./tag.large.component.css']
})
export class TagLargeComponent implements OnInit {
    @Input() active!: string;
    @Input() headerText!: string;
    @Input() bodyText!: string;
    @Input() color!: string;
    @Input() locked!: boolean;
    @Input() infoHead!: string;
    @Input() infoBody!: string;

    public visible!: boolean;
    public subject: any;
    public infoSubject: any;

    public style = {
        cursor: 'default',
        position: 'fixed',
        width: '250px',
        top: "" + "px",
        left: "" + "px",
    }
    
    closeInfoTag(value: boolean) {
      this.visible = value;
    }
  
    infoClickLarge(event: any){
      this.infoSubject.next(true);
      this.style.top = (String(event.pageY) + "px") ;
      this.style.left = (String(event.pageX) + "px");
      this.visible = !this.visible;
    }

    clickLarge(value: string){

        if (this.locked === false) {
          this.sensorTagObserverService.getSubjectTagTo()
          .next({name: this.headerText.toLowerCase(), visible: value == 'active'});

          switch (value) {
            case 'active':
              this.active = 'inactive';
              break;
            case 'inactive':
              this.active ='active';
              break;
            case 'construction':
              this.active = this.active;
              break;
          }
        } else { 
          return;
        }
    }

    constructor(private sensorTagObserverService: SensorTagObserverService,
                public infoTagObserverService: InfoTagObserverService) {}
    
    ngOnInit() {
        this.infoSubject = this.infoTagObserverService.getSubjectCloseTag();
    }
}