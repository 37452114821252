import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SensorTagObserverService } from '../../service/sensor.tag.observer.service';

@Component({
  selector: 'app-shoppingcart',
  templateUrl: './shoppingcart.component.html',
  styleUrls: ['./shoppingcart.component.css']
})
export class ShoppingcartComponent implements OnInit {
  @Output() sensorTagName: EventEmitter<string> = new EventEmitter<string>();
  @Output() toggleDrag: EventEmitter<boolean> = new EventEmitter<boolean>();

  lngShoppingcart: number = 4.436837;
  latShoppingcart: number = 51.915396;

  default: boolean = true;
  public infoTagVisible: boolean;
  public infoHead!: string;
  public infoBody!: string;

  public style = {
    cursor: 'default',
    position: 'absolute',
    width: '250px',
    visibility: 'visible',
    transform: ""
  }

  public text = {
    def1: {
      header: 'Shopping cart',
      body: 'Sensor Display - A Sensor Display provides a represen- tation of the live sensor data from the neighbourhood. In this case a representation of the use of a shopping cart for transpor- tation within the neighborhood, indicating resourceful uses.'
    },
    def2: {
      header: 'Shopping cart',
      body: 'Sensor - Indication of the actual sensor and its status, active or inactive.'
    },
    def3: {
      header: 'Shopping cart',
      body: 'Gauge - Indication of the current ingenuity in the use of shopping carts. Including the most recent peak use value.'
    },
    def4: {
      header: 'Shopping cart',
      body: 'Chart - Indication of the ratio of types of shopping cart use, including \'Lidle\', \'resourceful\' and \'idle\'.'
    },
    def5: {
      header: 'Shopping cart',
      body: 'Grapher - An overview of ingenuity in the use of shop- ping carts, for the chosen time frame.'
    },
    def6: {
      header: 'Shopping cart',
      body: 'Identifier - Registers and categorizes the usage and status of a shopping cart.'
    },
  }

  moduleClick(moduleName: string, locationName: string, event: any) {
    this.style.transform = 'translate3d(' + (String(event.layerX)) + 'px,' + (String(event.layerY)) + 'px, 0px)';
    this.infoHead = (this.text as any)[locationName].header;
    this.infoBody = (this.text as any)[locationName].body;
    this.infoTagVisible = !this.infoTagVisible;
  }

  moduleDrag() {
    this.toggleDrag.emit(false);
  }

  mapDrag() {
    this.toggleDrag.emit(true);
  }

  toggleDetails() {
    this.default = !this.default;
  }

  whoAmI(sensorTagName: any) {
    this.sensorTagName.emit(sensorTagName);
    this.sensorTagObserverService.getSubjectModuleTo().next({ name: sensorTagName });
    this.mapDrag();
    this.infoTagVisible = false;
  }

  closeInfoTag(value: boolean) {
    this.infoTagVisible = value;
    this.mapDrag();
  }

  constructor(private sensorTagObserverService: SensorTagObserverService) {
    this.infoTagVisible = false;
  }

  ngOnInit(): void {

  }

}
