import { Component, ViewChild, ElementRef, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { InfoSvgComponent } from '../info-svg/info-svg.component';
import { RouterService } from '../service/router.service';
declare var $: any;

@Component({
    selector: 'app-module-view',
    templateUrl: './module-view.component.html',
    styleUrls: ['./module-view.component.css'],
})
export class ModuleViewComponent implements OnInit, OnDestroy {
    @ViewChild('infoTag') infoTag!: InfoSvgComponent;

    private subscription: any;
    public marginCompensation: boolean = true;
    public infoTagVisible!: boolean;
    public opened!: boolean;
    public headerText!: string;
    public bodyText!: string;
    public draggable: boolean = false;

    public style = {
        cursor: 'default',
        position: 'fixed',
        width: '250px',
        top: '0px',
        left: '0px',
        visibility: 'visible',
        transform: ""
    }
    public text = {
        rotterdam: {
            header: 'Display',
            body: 'City Display - An enabled Display, placed on the board. Multiple neighbourhood Displays can be connected to a Display of this kind.'
        },
        care: {
            header: 'Display',
            body: 'Category Display - An enabled Display, placed on the board. Up to 3 Sensors of the same colour / category can be connected to a Display of this kind. A Display can also be connected to another Display.'
        },
        giftWrap: {
            header: 'Sensor',
            body: 'An enabled Sensor, placed on the board. A Sensor can be connected - and thereby serves as input - to a Display of the same colour / category.'
        },
        outspokenness: {
            header: 'Display',
            body: 'Category Display - An enabled Display, placed on the board. Up to 3 Sensors of the same colour / category can be connected to a Display of this kind. A Display can also be connected to another Display.'
        },
        voicing: {
            header: 'Sensor',
            body: 'An enabled Sensor, placed on the board. A Sensor can be connected - and thereby serves as input - to a Display of the same colour / category.'
        },
        reviews: {
            header: 'Sensor',
            body: 'An enabled Sensor, placed on the board. A Sensor can be connected - and thereby serves as input - to a Display of the same colour / category.'
        },
        behaviourBall: {
            header: 'Sensor',
            body: 'An enabled Sensor, placed on the board. A Sensor can be connected - and thereby serves as input - to a Display of the same colour / category.'
        },
        dogLeash: {
            header: 'Sensor',
            body: 'An enabled Sensor, placed on the board. A Sensor can be connected - and thereby serves as input - to a Display of the same colour / category.'
        },
        purchases: {
            header: 'Sensor',
            body: 'An enabled Sensor, placed on the board. A Sensor can be connected - and thereby serves as input - to a Display of the same colour / category.'
        },
        trustMistrust: {
            header: 'Display',
            body: 'Category Display - An enabled Display, placed on the board. Up to 3 Sensors of the same colour / category can be connected to a Display of this kind. A Display can also be connected to another Display.'
        },
        loitering: {
            header: 'Sensor',
            body: 'An enabled Sensor, placed on the board. A Sensor can be connected - and thereby serves as input - to a Display of the same colour / category.'
        },
        boredom: {
            header: 'Display',
            body: 'Category Display - An enabled Display, placed on the board. Up to 3 Sensors of the same colour / category can be connected to a Display of this kind. A Display can also be connected to another Display.'
        },
        ingenuityInitiative: {
            header: 'Display',
            body: 'Category Display - An enabled Display, placed on the board. Up to 3 Sensors of the same colour / category can be connected to a Display of this kind. A Display can also be connected to another Display.'
        },
        shoppingCart: {
            header: 'Sensor',
            body: 'An enabled Sensor, placed on the board. A Sensor can be connected - and thereby serves as input - to a Display of the same colour / category.'
        },
        inclusionExclusion: {
            header: 'Display',
            body: 'Category Display - An enabled Display, placed on the board. Up to 3 Sensors of the same colour / category can be connected to a Display of this kind. A Display can also be connected to another Display.'
        },
        gates: {
            header: 'Sensor',
            body: 'An enabled Sensor, placed on the board. A Sensor can be connected - and thereby serves as input - to a Display of the same colour / category.'
        },
        crosswalk: {
            header: 'Sensor',
            body: 'An enabled Sensor, placed on the board. A Sensor can be connected - and thereby serves as input - to a Display of the same colour / category.'
        },
        broom: {
            header: 'Sensor',
            body: 'An enabled Sensor, placed on the board. A Sensor can be connected - and thereby serves as input - to a Display of the same colour / category.'
        },
        bospolder: {
            header: 'Display',
            body: 'Neighbourhood Display - An enabled Display, placed on the board. Up to 3 Displays of multiple colour / category can be connected to a Display of this kind. A Display can also be connected to another Display.'
        },
        reyeroord: {
            header: 'Display',
            body: 'Neighbourhood Display - An enabled Display, placed on the board. Up to 3 Displays of multiple colour / category can be connected to a Display of this kind. A Display can also be connected to another Display.'
        },
    }

    checkViewerPosition() {
        let params = this.routerService.getQueryParams();

        if (params.barOpen === 'true') {
            $('#viewer').css({ left: 455 })
            this.opened = true;
        } else if (params.barOpen === 'false') {
            $('#viewer').css({ left: 195 })
            this.opened = false;
        }
    }

    animateLock() {
        if(this.routerService.getBaseUrl().includes('moduleview')) {
            let value = true;
            let params = this.routerService.getQueryParams();
            
            if(params.tagClicked === 'true') {
                value = false;
            }

            this.routerService.addParamToUrl({
                name: 'tagClicked',
                value: value
            })
        }
    }

    openItem(name: string, event: any) {
        this.style.transform = 'translate3d(' + (String(event.pageX)) + 'px,' + (String(event.pageY)) + 'px, 0px)';
        this.headerText = (this.text as any)[name].header;
        this.bodyText = (this.text as any)[name].body;
        this.infoTagVisible = !this.infoTagVisible;
    }

    closeInfoTag(value: boolean) {
        this.infoTagVisible = value;
        this.mapDrag();
    }
    
    tagDrag() {
        this.draggable = true;
    }

    mapDrag() {
        this.draggable = false;
    }

    constructor(private router: Router,
        private activatedRoute: ActivatedRoute,
        private routerService: RouterService) {
        this.infoTagVisible = false;
    }

    ngOnInit() {
        this.checkViewerPosition();

        $('.modole-view-image').on("scroll", function () {
            if ($(document).scrollTop() == 0) {
                console.log('true');
            } else {
                console.log('else');
            }
        });

        this.subscription = this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.checkViewerPosition();
            }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}