import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { RouterService } from '../service/router.service';
declare var $: any;

@Component({
  selector: 'app-static-menu',
  templateUrl: './static-menu.component.html',
  styleUrls: ['./static-menu.component.css']
})
export class StaticMenuComponent implements OnInit, OnDestroy {
    private subscription: any;
    public location!: string;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private routerService: RouterService) { }

    ngOnInit(): void {
        this.subscription = this.router.events.subscribe((val) => {
            if(val instanceof NavigationEnd) {                
                if(this.routerService.urlIsEmpty()) {
                    this.addToUrl('rotterdam', this.getSideBarStatus());
                }

                this.location = this.routerService.getQueryParams().location;
            }
        });
    }
    public menuStyle: object = {backgroundColor: '#bcbec0'}
    public nos = 'bospolder';

    locationMenuStyling(location: string) {
        switch (location) {
            case 'rotterdam':
                this.menuStyle = {backgroundColor: '#bcbec0'};
                break;
            case 'reyeroord':
                this.menuStyle = {backgroundColor: '#dc4427'};
                break;
            case 'bospolder':
                this.menuStyle = {backgroundColor: '#278a45'};
                break;
        }
    }

    addToUrl(location: string, barOpen: any = null) {
        this.location = location;
        this.routerService.addToUrl(location, barOpen);
        this.locationMenuStyling(location);
    }

    private getSideBarStatus() {
        return $('.sidebar').attr('class').split(/\s+/).includes('active');
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
