import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { RouterService } from '../service/router.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit, OnDestroy {
    public clicked = false
    private subscription: any;

    constructor(private router: Router, private routerService: RouterService) { }

    ngOnInit(): void {
        this.subscription = this.router.events.subscribe((val) => {
            if(val instanceof NavigationEnd) {
                let baseUrl = this.routerService.getBaseUrl();
                this.clicked = baseUrl.includes('boardview');
            }
        });
    }

    changePage() {
        let params = this.routerService.getQueryParams();
        if(this.router.url.includes('boardview')) {
            params['location'] = 'rotterdam';
            this.routerService.goToUrl('/rtdm/dashboard', params);
        } else {
            this.routerService.goToUrl('/rtdm/dashboard/boardview', params);
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
