<!-- <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 524.32 772.46"><defs><style>.infocls-1{fill:#e6e7e8;}.infocls-2{fill:none;}.infocls-2,.infocls-3,.infocls-8{stroke:#bcbec0;}.infocls-2,.infocls-26,.infocls-3,.infocls-8{stroke-miterlimit:10;}.infocls-27,.infocls-3,.infocls-8{font-size:39.01px;}.infocls-3,.infocls-8{fill:#231f20;}.infocls-27,.infocls-3{font-family:Bolder_Bold, Bolder;font-weight:700;}.infocls-4{letter-spacing:0em;}.infocls-5{letter-spacing:-0.02em;}.infocls-6{letter-spacing:-0.07em;}.infocls-7{letter-spacing:-0.01em;}.infocls-8{font-family:Bolder_Light, Bolder;}.infocls-9{letter-spacing:-0.01em;}.infocls-10{letter-spacing:0em;}.infocls-11{letter-spacing:-0.08em;}.infocls-12{letter-spacing:-0.02em;}.infocls-13{letter-spacing:-0.02em;}.infocls-14{letter-spacing:0em;}.infocls-15{letter-spacing:0em;}.infocls-16{letter-spacing:-0.01em;}.infocls-17{letter-spacing:-0.01em;}.infocls-18{letter-spacing:-0.02em;}.infocls-19{letter-spacing:-0.01em;}.infocls-20{letter-spacing:-0.02em;}.infocls-21{letter-spacing:-0.01em;}.infocls-22{letter-spacing:-0.01em;}.infocls-23{letter-spacing:-0.03em;}.infocls-24{letter-spacing:0.01em;}.infocls-25{letter-spacing:0em;}.infocls-26{fill:#fff;stroke:#dc4427;stroke-width:6px;}.infocls-27{fill:#6d6e71;}</style></defs><rect class="infocls-1" x="0.5" y="0.5" width="523.32" height="771.46"/><rect class="infocls-2" x="0.5" y="0.5" width="523.32" height="771.46"/>
    <text class="infocls-3" transform="translate(52.91 124.29)">{{headerText}}</text>
    <text class="infocls-8" id="svgText" transform="translate(52.91 150.73)"></text>
    <line class="infocls-26" style="cursor: pointer;" (click)="close()" x1="46.24" y1="21.65" x2="30.59" y2="37.3"/><line class="infocls-26" style="cursor: pointer;" (click)="close()" x1="46.24" y1="37.3" x2="30.59" y2="21.65"/><text class="infocls-27" transform="translate(479.3 59.6)">i</text>
</svg> -->
<!-- <text class="infocls-8" id="svgText" transform="translate(52.91 178.73)"></text> -->

<!-- works fine -->
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 524.32 772.46"><defs><style>.infocls-1{fill:#e6e7e8;}.infocls-2{fill:none;}.infocls-2,.infocls-3,.infocls-8{stroke:#bcbec0;}.infocls-2,.infocls-26,.infocls-3,.infocls-8{stroke-miterlimit:10;}.infocls-27,.infocls-3,.infocls-8{font-size:39.01px;}.infocls-3,.infocls-8{fill:#231f20;}.infocls-27,.infocls-3{font-family:Bolder_Bold, Bolder;font-weight:700;}.infocls-4{letter-spacing:0em;}.infocls-5{letter-spacing:-0.02em;}.infocls-6{letter-spacing:-0.07em;}.infocls-7{letter-spacing:-0.01em;}.infocls-8{font-family:Bolder_Light, Bolder;}.infocls-9{letter-spacing:-0.01em;}.infocls-10{letter-spacing:0em;}.infocls-11{letter-spacing:-0.08em;}.infocls-12{letter-spacing:-0.02em;}.infocls-13{letter-spacing:-0.02em;}.infocls-14{letter-spacing:0em;}.infocls-15{letter-spacing:0em;}.infocls-16{letter-spacing:-0.01em;}.infocls-17{letter-spacing:-0.01em;}.infocls-18{letter-spacing:-0.02em;}.infocls-19{letter-spacing:-0.01em;}.infocls-20{letter-spacing:-0.02em;}.infocls-21{letter-spacing:-0.01em;}.infocls-22{letter-spacing:-0.01em;}.infocls-23{letter-spacing:-0.03em;}.infocls-24{letter-spacing:0.01em;}.infocls-25{letter-spacing:0em;}.infocls-26{fill:#fff;stroke:#dc4427;stroke-width:6px;}.infocls-27{fill:#6d6e71;}</style></defs><rect class="infocls-1" x="0.5" y="0.5" width="523.32" height="771.46"/><rect class="infocls-2" x="0.5" y="0.5" width="523.32" height="771.46"/>
    <foreignObject x="50" y="75" width="430" height="550">
        <div class="headerStyling">{{headerText}}</div>
        <div class="bodyStyling">{{bodyText1}}<br><br>{{bodyText2}}</div>
        <!-- <div class="bodyStyling">{{bodyText}}</div> -->
    </foreignObject>
    <line class="infocls-26" style="cursor: pointer;" (click)="close()" x1="46.24" y1="21.65" x2="30.59" y2="37.3"/><line class="infocls-26" style="cursor: pointer;" (click)="close()" x1="46.24" y1="37.3" x2="30.59" y2="21.65"/><text class="infocls-27" transform="translate(479.3 59.6)">i</text>
</svg>