import { ThrowStmt, VariableAst } from '@angular/compiler';
import { Component, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { map } from 'jquery';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { GoogleMapsAPIWrapper } from '@agm/core';
import { AgmMapControl } from '@agm/core/lib/directives/map';
import { AgmOverlay } from 'agm-overlays';
import { AgmMap, MapsAPILoader } from '@agm/core';
import { RouterService } from '../service/router.service';

declare var $: any;
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
  animations: [
    // Each unique animation requires its own trigger. The first argument of the trigger function is the name
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(180deg)' })),
      transition('rotated => default', animate('300ms ease-out')),
      transition('default => rotated', animate('300ms ease-in'))
    ]),
    trigger('inOutAnimation', [
        transition(':enter', 
            [
            style({opacity: 0 }),
            animate('0.5s ease-out', 
                    style({opacity: 1 }))
            ]
        ),
        transition(':leave', [
            style({ opacity: 1 }),
            animate('0.5s ease-in', 
                    style({opacity: 0 }))
            ])
    ])
]
})

export class MapComponent implements OnInit, OnDestroy {
    private subscription: any;
    
    //modules active/inactive
    //reyeroord
    outspokeness: boolean = false;
    voicing: boolean = false;
    reviews: boolean = false;
    sensorObject: boolean = false;
    trustMistrust: boolean = false;
    dogLeash: boolean = false;
    purchases: boolean = false;
    boredom: boolean = false;
    loitering: boolean = false;
    //bospolder
    care: boolean = false;
    giftwrap: boolean = false;
    crosswalk: boolean = false;
    broom: boolean = false;
    ingenuityInitiative: boolean = false;
    shoppingcart: boolean = false;
    inclusionExclusion: boolean = false;
    gates: boolean = false;

    //functionality of menu, map and overlays
    opened: boolean = false;
    _dock: boolean = true;
    draggable: boolean = true;
    reyeroord: boolean = false;
    bospolder: boolean = false;
    fillSvg: boolean = false;
    visibilityReyeroord: string = 'hidden';
    visibilityBospolder: string = 'hidden';

    //initial zoom level
    zoom = 14;
    // IMPORTANT NOTE: Lat and Lng is switched, meaning wise
    //center coordinates - Does lat and lng work? - centers the overlays
    lat = 51.8958;
    lng = 4.502525;
    latRey = 51.874821;
    lngRey = 4.557217;
    latBos = 51.905690;
    lngBos = 4.4434;

    //centering arrays - google map viewport
    latMarker = 51.916671;
    lngMarker = 4.432951;
    latMarker2 = 51.878931;
    lngMarker2 = 4.565517;
    lngReyMarker = 4.546271;
    latReyMarker = 51.885794;
    lngReyMarker2 = 4.560262;
    latReyMarker2 = 51.876048;
    lngBosMarker = 4.430022;
    latBosMarker = 51.916694;
    lngBosMarker2 = 4.447323;
    latBosMarker2 = 51.905005;
        //51.875199 4.567963
    latLngCenteringArray = [
        {latitude: 51.916671, longitude: 4.432951},
        {latitude: 51.875199, longitude: 4.567963}
      ]

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                private route: ActivatedRoute,
                private routerService: RouterService) { }

    goToReyeroord() {
        this.lat = 51.881238;
        this.lng = 4.556957;
        this.zoom = 16;
        this.fillSvg = true;
        this.reyeroord = true;
        this.bospolder = false;
        this.visibilityReyeroord = 'visible'
        this.visibilityBospolder = 'hidden'
        this.latLngCenteringArray.splice(0, 2, {latitude: this.latReyMarker, longitude: this.lngReyMarker}, {latitude: this.latReyMarker2, longitude: this.lngReyMarker2})
      }
    
      goToBotu() {
        this.lat = 51.910865;
        this.lng = 4.442822;
        this.zoom = 16;
        this.fillSvg = true;
        this.bospolder = true;
        this.reyeroord = false;
        this.visibilityReyeroord = 'hidden'
        this.visibilityBospolder = 'visible'
        this.latLngCenteringArray.splice(0, 2, {latitude: this.latBosMarker, longitude: this.lngBosMarker}, {latitude: this.latBosMarker2, longitude: this.lngBosMarker2})
      }
      goToRotterdam() {
        this.lat = 51.8958;
        this.lng = 4.502525;
        this.zoom = 14;
        this.fillSvg = false;
        this.reyeroord = false;
        this.bospolder = false;
        this.visibilityReyeroord = 'hidden'
        this.visibilityBospolder = 'hidden'
        this.latLngCenteringArray.splice(0, 2, {latitude: this.latMarker, longitude: this.lngMarker}, {latitude: this.latMarker2, longitude: this.lngMarker2});
      }
    
  openUp(sensorTagName: string){
    switch (sensorTagName) {
        //reyeroord
        case'outspokenness': {
            this.outspokeness = !this.outspokeness;
            break;
        }
        case'voicing': {
            this.voicing = !this.voicing;
            break;
        }
        case'reviews': {
            this.reviews = !this.reviews;
            break;
        }
        case'behaviour ball': {
            this.sensorObject = !this.sensorObject
            break;
        }
        case'trustMistrust': {
            this.trustMistrust = !this.trustMistrust;
            break;
        }
        case'dog leash': {
            this.dogLeash = !this.dogLeash;
            break;
        }
        case'purchases': {
            this.purchases = !this.purchases;
            break;
        }
        case'boredom': {
            this.boredom = !this.boredom;
            break;
        }
        case'loitering': {
            this.loitering =!this.loitering;
            break;
        }
        //bospolder
        case'care': {
            this.care = !this.care;
            break;
        }
        case'gift wrap': {
            this.giftwrap = !this.giftwrap;
            break;
        }
        case'crosswalk': {
            this.crosswalk = !this.crosswalk;
            break;
        }
        case'broom': {
            this.broom = !this.broom;
            break;
        }
        case'ingenuityInitiative': {
            this.ingenuityInitiative = !this.ingenuityInitiative;
            break;
        }
        case'shopping cart': {
            this.shoppingcart = !this.shoppingcart;
            break;
        }
        case'inclusionExclusion': {
            this.inclusionExclusion = !this.inclusionExclusion;
            break;
        }
        case'gates': {
            this.gates = !this.gates;
            break;
        }
    }
  }

//map styling
  styles: google.maps.MapTypeStyle[] = [
    {
        "featureType": "administrative",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#444444"
            }
        ]
    },
    {
        "featureType": "administrative.province",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.province",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.locality",
        "elementType": "geometry",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.neighborhood",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.neighborhood",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.land_parcel",
        "elementType": "geometry",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.land_parcel",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f2f2f2"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#fffefc"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#c6c6c6"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    }
];

//sidebar logic
  state: string = 'default';
  toggleSidebar() {
    this.opened = !this.opened;
    this._dock = !this.opened
  };

rotate() {
    this.state = (this.state === 'default' ? 'rotated' : 'default');
} ;

    ngOnInit(): void {
        this.getUrl();

        this.subscription = this.router.events.subscribe((val) => {
            if(val instanceof NavigationEnd) {
                this.getUrl();
            }
        });
    }

    getUrl() {
        let parameters = window.location.search.substring(window.location.search.indexOf("?") + 1);
        if(parameters !== '') {
            let obj = JSON.parse('{"' + decodeURI(parameters.replace(/&/g, "\",\"").replace(/=/g,"\":\"")) + '"}');
            this.showMapLocation(obj.location);
        }
    }

    showMapLocation(name: string) {
        switch(name) {
            case 'rotterdam':
                this.goToRotterdam();
                break;
            case 'reyeroord':
                this.goToReyeroord();
                // this.goToRotterdam();
                // setTimeout(() => {
                //     $(this.goToReyeroord());
                // }, 850);
                break;
            case 'bospolder':
                this.goToBotu();
                // this.goToRotterdam();
                // setTimeout(() => {
                //     $(this.goToBotu());
                // }, 850);
                break;
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    addToUrl(location: string, barOpen: any = null) {
        this.routerService.addToUrl(location, barOpen);
    }
}