import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StatesService {
  public opened: boolean = false;
  marginCompensation: boolean = true;
  _dock: boolean = true;
  state: string = 'default';
  
  toggleSidebar() {
    this.opened = !this.opened;
    this.marginCompensation = !this.marginCompensation;
    this.state = (this.state === 'default' ? 'rotated' : 'default');
};
  constructor() { }
}
