<div class="mapControl not-allowed" id="dPad">
    <img src="../../assets/dpad-icon.svg">
</div>
<div class="mapControl not-allowed" id="grid">
    <img src="../../assets/grid-icon.svg">
</div>
<div class="mapControl" id="timeStamp">
    <div id="live"></div>
    <span>{{localTimeISOString}}</span>
</div>

