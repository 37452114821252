import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';;

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.css']
})
export class LogoComponent implements OnInit {

    constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goHome() {
    this.router.navigate(['/rtdm/dashboard']);
  }
}
