<a class="button" id="rotterdam" (click)="addToUrl('rotterdam')">
    <div class="menuItems" [ngStyle]="{'background-color': location === 'rotterdam' ? 'rgb(243, 243, 244)' : 'rgb(255, 255, 255)'}">
        <span>Rotterdam</span>
        <div id="gray"></div>
    </div>
</a>
<a class="button" id="reyeroord" (click)="addToUrl('reyeroord', true)">
    <div class="menuItems" [ngStyle]="{'background-color': location === 'reyeroord' ? 'rgb(247, 210, 191)' : 'rgb(255, 255, 255)'}">
        <span>Reyeroord</span>
        <div id="red"></div>
    </div>
</a>
<a class="button" id="bospolder" (click)="addToUrl('bospolder', true)">
    <div class="menuItems" [ngStyle]="{'background-color': location === 'bospolder' ? 'rgb(205, 219, 200)' : 'rgb(255, 255, 255)'}">
        <span>Bospold…</span>
        <div id="green"></div>
    </div>
</a>