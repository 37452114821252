<div id="top-row" *ngIf="isLoggedIn">
    <app-logo></app-logo>
    <app-mid-header></app-mid-header>
    <app-settings></app-settings>
</div>
<div id="mid-row" *ngIf="isLoggedIn">
    <app-static-menu></app-static-menu>
    <app-mid></app-mid>
    <app-ruler></app-ruler>
</div>

<div id="top-row" *ngIf="!isLoggedIn">
    <app-logo></app-logo>
    <app-mid-header></app-mid-header>
</div>
<div id="mid-row" *ngIf="!isLoggedIn">
    <app-login></app-login>
</div>