import { getLocaleTimeFormat } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { timer } from "rxjs";
import { map, take } from "rxjs/operators";


@Component({
  selector: 'app-control-overlay',
  templateUrl: './control-overlay.component.html',
  styleUrls: ['./control-overlay.component.css']
})
export class ControlOverlayComponent implements OnInit {
  localTimeISOString: any;
  localTime: any;
  constructor() { }
  
  ngOnInit(): void {
    setInterval(() => { 
      this.localTime = new Date()
      this.localTime.setHours(this.localTime.getHours()+2);
      this.localTimeISOString = this.localTime.toISOString().split("-").join("")
      .split(":").join("")
      .slice(0,15).concat("Z"); 
    }, 1000);}

}
