import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SensorTagObserverService {
    
    private subjectTagTo = new Subject();

    private subjectModuleTo = new Subject();

    private subjectSTagTo = new Subject();

    constructor() {}

    getSubjectTagTo() {
        return this.subjectTagTo;
    }

    getSubjectModuleTo() {
        return this.subjectModuleTo;
    }

    getSubjectSTagTo(){
        return this.subjectSTagTo;
    }

}
